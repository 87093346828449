import React, { Suspense, SuspenseProps, useEffect, useState } from 'react'

export const SuspenseAfterInitialRender = ({ fallback, children }: SuspenseProps) => {
  const [isInitialRender, setIsInitialRender] = useState(true)

  return isInitialRender ? (
    <>
      <Lifecycle afterRender={() => setIsInitialRender(false)} />
      {children}
    </>
  ) : (
    <Suspense fallback={fallback}>{children}</Suspense>
  )
}

type LifecycleProps = {
  afterRender: () => void
}

export const Lifecycle = ({ afterRender }: LifecycleProps): null => {
  useEffect(() => {
    afterRender()
  }, [afterRender])

  return null
}
