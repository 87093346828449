import React, { FunctionComponent, LazyExoticComponent, Suspense, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router'

import { Loader } from 'components'
import { IS_DEV } from 'config/env'
import { useToast } from 'hooks/use-toast.hook'

type LoadComponentProps = {
  props?: any
  component: LazyExoticComponent<FunctionComponent>
}

const GlobalErrorFallback = (): null => {
  const navigate = useNavigate()
  const { showUnexpectedToast } = useToast()

  useEffect(() => {
    if (IS_DEV) {
      showUnexpectedToast()
      return
    }

    navigate('/500')
  }, [])

  return null
}

export const LoadComponent = ({ component: Component, props }: LoadComponentProps) => (
  <ErrorBoundary FallbackComponent={GlobalErrorFallback}>
    <Suspense fallback={<Loader preset='cover-page' />}>
      <Component {...props} />
    </Suspense>
  </ErrorBoundary>
)
