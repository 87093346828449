import React from 'react'
import { Form, FormCheckProps } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type SwitchProps = FormCheckProps & {
  label: string
  name: string
  trueLabel?: string
  falseLabel?: string
  containerClass?: string
}

export const ControlledSwitch = (props: SwitchProps) => {
  const { t } = useTranslation()
  const {
    label,
    name,
    trueLabel = t('Yes'),
    falseLabel = t('No'),
    containerClass,
    ...otherProps
  } = props

  const { register, control } = useFormContext()
  const {
    field: { value },
    fieldState: { invalid, error },
  } = useController({ control, name })

  return (
    <Form.Group className={containerClass}>
      <Form.Label>{label}</Form.Label>
      <Form.Check
        id='custom-switch'
        label={value ? trueLabel : falseLabel}
        isInvalid={invalid}
        {...register(name)}
        {...otherProps}
        type='switch'
      />

      {error && (
        <Form.Control.Feedback type='invalid' className='d-block'>
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
