import { useState } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { useStores } from 'models'
import { capitalize } from 'lodash'

export default function useForgotPassword() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    authStore: { forgotPassword, setValue },
  } = useStores()

  const [error, setError] = useState(null)

  const forgotPassResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .email(t('Please enter a valid email'))
        .required(t('Please enter your email')),
    })
  )

  const navigateToVerification = () => {
    navigate('/account/verify-reset-password', { replace: true })
  }

  const backTo = async () => {
    navigate('/account/login')
    sessionStorage.clear()
  }

  const doForgotPassword = async (data: any) => {
    try {
      setValue('email', data.email)
      const submit = await forgotPassword(data)

      if (submit) navigateToVerification()
    } catch (error) {
      setError(capitalize(error?.data?.errors))
    }
  }
  return { forgotPassResolver, doForgotPassword, error, backTo }
}
