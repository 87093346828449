import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { CurrencyType } from 'types/global.types'

export const PaymentForOrganizerModel = types.model('PaymentForOrganizer').props({
  id: types.maybe(types.number),
  actualPayoutDate: types.maybeNull(types.string),
  challengeID: types.maybeNull(types.number),
  completionDate: types.maybeNull(types.string),
  eventName: types.maybeNull(types.string),
  organizerEmail: types.maybeNull(types.string),
  organizerName: types.maybeNull(types.string),
  paymentReference: types.maybeNull(types.string),
  paymentStatus: types.maybeNull(types.string),
  paymentTag: types.maybeNull(types.string),
  payoutDate: types.maybeNull(types.string),
  userID: types.maybeNull(types.number),
})

export type PaymentForOrganizerInstance = Instance<typeof PaymentForOrganizerModel>
export type PaymentForOrganizer = Omit<PaymentForOrganizerInstance, symbol>
export type PaymentForOrganizerSnapshot = SnapshotOut<typeof PaymentForOrganizerModel>

export const PaymentDetailOrganizerModel = types.model('PaymentDetailOrganizer').props({
  id: types.maybe(types.number),
  challengeID: types.maybeNull(types.number),
  accountHolderName: types.maybeNull(types.string),
  accountNumber: types.maybeNull(types.string),
  accountingForRefund: types.maybeNull(types.string),
  actualPayoutDate: types.maybeNull(types.string),
  adminFee: types.maybeNull(types.string),
  amountToRemit: types.maybeNull(types.string),
  bankName: types.maybeNull(types.string),
  completionDate: types.maybeNull(types.string),
  eventName: types.maybeNull(types.string),
  eventSales: types.maybeNull(types.string),
  organizerEmail: types.maybeNull(types.string),
  organizerEntity: types.maybeNull(types.string),
  organizerName: types.maybeNull(types.string),
  paymentReference: types.maybeNull(types.string),
  paymentStatus: types.maybeNull(types.string),
  paymentTag: types.maybeNull(types.string),
  payoutDate: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  phoneNumberCode: types.maybeNull(types.string),
  proofOfTransfer: types.maybeNull(types.string),
  currency: types.maybe(types.enumeration<CurrencyType>(['usd', 'eur', 'idr'])),
})

export type PaymentDetailOrganizerInstance = Instance<typeof PaymentDetailOrganizerModel>
export type PaymentDetailOrganizer = Omit<PaymentDetailOrganizerInstance, symbol>
export type PaymentDetailOrganizerSnapshot = SnapshotOut<typeof PaymentForOrganizerModel>

export const RefundPaymentsModel = types.model('RefundPayments').props({
  id: types.maybe(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  paymentStatus: types.maybeNull(types.string),
  eventName: types.maybeNull(types.string),
  participantName: types.maybeNull(types.string),
  emailAddress: types.maybeNull(types.string),
  amount: types.maybeNull(types.number),
  currency: types.maybeNull(types.enumeration<CurrencyType>(['usd', 'eur', 'idr'])),
  actualPayoutDate: types.maybeNull(types.string),
})

export type RefundPaymentsInstance = Instance<typeof RefundPaymentsModel>
export type RefundPayments = Omit<RefundPaymentsInstance, symbol>
export type RefundPaymentsSnapshot = SnapshotOut<typeof RefundPaymentsModel>

export const AdminRefundsPaymentsModel = types.model('AdninRefundsPayments').props({
  actualPayoutDate: types.maybeNull(types.string),
  challengeID: types.maybe(types.number),
  challengeName: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  participantName: types.maybeNull(types.string),
  paymentId: types.maybeNull(types.string),
  paymentStatus: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  ticketName: types.maybeNull(types.string),
  transactionDetailsID: types.maybeNull(types.number),
  userID: types.maybeNull(types.number),
  currency: types.maybeNull(types.enumeration<CurrencyType>(['usd', 'eur', 'idr'])),
})

export type AdminRefundsPaymentsInstance = Instance<typeof AdminRefundsPaymentsModel>
export type AdminRefundsPayments = Omit<AdminRefundsPaymentsInstance, symbol>
export type AdminRefundsPaymentsSnapshot = SnapshotOut<typeof AdminRefundsPaymentsModel>
