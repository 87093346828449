import { USER_TYPE } from 'config/constant'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import moment from 'moment'
import { OrganizationType, UserType } from 'types/global.types'

export const CreatorPersonalInfo = types.model({
  BankAccountHolder: types.maybeNull(types.string),
  accountNumber: types.maybeNull(types.string),
  bankName: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  deleted_at: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  photo: types.maybeNull(types.frozen()),
  personalInfoType: types.maybeNull(types.number),
  website: types.optional(types.string, ''),
  instagram: types.optional(types.string, ''),
  updatedAt: types.maybeNull(types.string),
})

export const ProfileModel = types
  .model('Profile')
  .props({
    id: types.maybeNull(types.number),
    photoProfile: types.maybeNull(types.frozen()),
    fullName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    userLevel: types.maybeNull(types.number),
    birthday: types.maybeNull(types.string),
    phoneNumberCode: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    occupation: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    creatorPersonalInfo: types.maybeNull(CreatorPersonalInfo),
  })
  .views((self) => ({
    get userType(): UserType {
      switch (self.userLevel) {
        case 0:
          return 'super-admin'
        case 1:
        default:
          return 'admin'
      }
    },
    get organizationType(): OrganizationType {
      switch (self.creatorPersonalInfo?.personalInfoType) {
        case 1:
          return 'individual'
        case 2:
          return 'company'
        case 3:
          return 'charity'
        default:
          return 'individual'
      }
    },
    get isAdmin() {
      return self.userLevel === USER_TYPE.ADMIN.level
    },
    get isSuperAdmin() {
      return self.userLevel === USER_TYPE.SUPER_ADMIN.level
    },
    get formatedDateJoined() {
      return moment(self.createdAt).format('Do MMM YYYY')
    },
    get formatedBirthday() {
      return moment(self.birthday).format('DD MMM YYYY')
    },
  }))

export type ProfileInstance = Instance<typeof ProfileModel>
export type Profile = Omit<ProfileInstance, symbol>
export type ProfileSnapshot = SnapshotOut<typeof ProfileModel>

export const PaymentAnalyticsModel = types.model('PaymentAnalytics').props({
  total: types.optional(types.number, 0),
  totalPermonth: types.optional(types.number, 0),
  status: types.maybeNull(types.string),
})

export type PaymentAnalyticsInstance = Instance<typeof PaymentAnalyticsModel>
export type PaymentAnalytics = Omit<PaymentAnalyticsInstance, symbol>
export type PaymentAnalyticsSnapshot = SnapshotOut<typeof PaymentAnalyticsModel>
