import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { logoBannerWhite, noInternetConnection } from 'assets/images'
import { useAccountLayout } from 'hooks'
import Image from 'components/image'

const NoInternetConnection = () => {
  useAccountLayout()

  const { t } = useTranslation()

  return (
    <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
      <div className='container'>
        <Row className='justify-content-center'>
          <Col md={8} lg={6} xl={5} xxl={4}>
            <Card>
              <Card.Header className='py-3 text-center bg-black'>
                <Link to='/'>
                  <Image image={logoBannerWhite} height={24} width={100} />
                </Link>
              </Card.Header>
              <Card.Body className='px-4 py-2'>
                <div className='text-center'>
                  <img src={noInternetConnection} height='120' alt='' />
                  <h5 className='text-black mt-3'>{t('Problem with connection')}</h5>
                  <p className='text-neutral-4 fw-semibold mx-4 my-0'>
                    {t('Woops, there seems to be a problem with your network Connection.')}
                  </p>
                  <Link className='btn btn-primary my-3 fw-bold' to='/'>
                    {t('Try Again')}
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default NoInternetConnection
