import { OptionsProps, OptionProps } from './select.types'

export const converValuesToString = (collection: OptionsProps): string | null => {
  if (!collection.length) return null

  const valueArr = collection.map((item) => item.value)

  return valueArr.join(',')
}

export const getValuesFromString = (values: string, source: OptionsProps): OptionsProps => {
  if (!values) return []

  const valueArr = values.split(',')

  const options = valueArr.reduce<OptionProps[]>((coll, value) => {
    const option = source.filter((item) => item.value === value)[0]

    if (option) coll.push(option)

    return coll
  }, [])

  return options
}
