import { IStateTreeNode, applySnapshot } from 'mobx-state-tree'

/**
 * Reset property to the default state.
 */
export const withReset = (self: IStateTreeNode) => ({
  reset(): void {
    applySnapshot(self, {})
  },
})
