import React, { FunctionComponent } from 'react'
import { Alert, Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { FormInput, SubmitButton, VerticalForm } from 'components'

import useRegister from './register.hook'
import { Link } from 'react-router-dom'
import { logoBannerWhite } from 'assets/images'
import Icon from 'components/icon'
import Image from 'components/image'

const Register: FunctionComponent = observer(() => {
  const { t } = useTranslation()

  const {
    isLoading,
    generalInfo,
    error,
    isAble,
    isShowModal,
    registerResolver,
    onRegister,
    handleBack,
    setIsAble,
    setIsShowModal,
  } = useRegister()

  return (
    <>
      <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5} xxl={4}>
              <Card>
                <Card.Header className='py-3 text-center bg-black'>
                  <Link to='/'>
                    <Image image={logoBannerWhite} height={24} width={100} />
                  </Link>
                </Card.Header>
                <Card.Body className='px-4 pt-4 pb-2'>
                  <div className='m-auto text-center'>
                    <h4 className='text-black mt-0'>{t('Register')}</h4>
                    <p className='text-neutral-4 fw-semibold'>
                      {t('Enter your information to create an account')}
                    </p>
                  </div>
                  {error && (
                    <Alert variant='danger' className='my-2'>
                      {error}
                    </Alert>
                  )}
                  <VerticalForm onSubmit={onRegister} resolver={registerResolver}>
                    <FormInput
                      label={t('Email')}
                      type='email'
                      name='email'
                      placeholder={t('Your email')}
                      containerClass={'mb-3'}
                    />
                    <FormInput
                      label={t('Password')}
                      type='password'
                      name='password'
                      placeholder={t('Your password')}
                      containerClass={'mb-3'}
                    />
                    <FormInput
                      label={t('Confirm password')}
                      type='password'
                      name='confirmPassword'
                      placeholder={t('Confirm your password')}
                      containerClass={'mb-3'}
                    />
                    <div className='d-flex justify-content-between gap-2 mb-3'>
                      <input
                        type='checkbox'
                        name='acceptTerms'
                        id='acceptTerms'
                        onChange={(event) => setIsAble(event.target.checked)}
                        style={{ width: '25px', height: '25px' }}
                      />
                      <p
                        onClick={() => setIsShowModal(true)}
                        className='m-0 clickable text-neutral-5'
                      >
                        {t(
                          'I agree to the Community Guidelines, User Agreement, and Commercial Terms'
                        )}
                      </p>
                    </div>
                    <div className='d-flex justify-content-center'>
                      <SubmitButton loading={isLoading} size='lg' disabled={!isAble}>
                        {t('Register')}
                      </SubmitButton>
                    </div>
                    <div className='text-center mt-3'>
                      <p className='text-neutral-4'>
                        {t('Already have an account? ')}
                        <Link
                          to='/account/login'
                          className='text-primary-blue fw-600'
                          onClick={handleBack}
                        >
                          {t('Login here')}
                        </Link>
                      </p>
                    </div>
                  </VerticalForm>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {isShowModal && (
        <Modal
          show={isShowModal}
          centered
          contentClassName='modal-rounded'
          onHide={() => setIsShowModal(false)}
          size='lg'
        >
          <div className='px-3 py-3'>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <h3 className='text-black font-18 fw-bold'>
                <span dangerouslySetInnerHTML={{ __html: generalInfo?.title }} />
              </h3>
              <button onClick={() => setIsShowModal(false)} className='btn-unstyled p-0'>
                <Icon icon='icon-close' className='text-black' size={20} />
              </button>
            </div>
            <div>
              <div className='border rounded rounded-3 mt-1'>
                <Row className='p-2'>
                  <Col>
                    <span dangerouslySetInnerHTML={{ __html: generalInfo?.description }} />
                  </Col>
                </Row>
              </div>
              <div className='d-flex justify-content-end gap-1 mt-3'>
                <Button size='lg' className='fw-bold' onClick={() => setIsShowModal(false)}>
                  {t('Close')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
})

export default Register
