import { FileType, TransformedFileType } from './file-uploader.types'

export default function useFileUploader(
  showPreview = true,
  onFileUpload?: (files?: TransformedFileType[]) => void
) {
  /**
   * Handled the accepted files and shows the preview
   */
  const handleAcceptedFiles = (files: FileType[]) => {
    let transformedFiles: TransformedFileType[] = []

    if (showPreview) {
      // @ts-ignore
      transformedFiles = files.map((file) => {
        return {
          id: file.id,
          image: {
            ...file,
            preview: file.type.split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
            formattedSize: formatBytes(file.size),
            name: file.name,
          },
          ogFile: file,
        }
      })
    }

    if (onFileUpload) onFileUpload(transformedFiles)
  }

  /**
   * Formats the size
   */
  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  return {
    handleAcceptedFiles,
  }
}
