import React, { FC, useContext } from 'react'
import { AccordionContext, useAccordionButton } from 'react-bootstrap'
import classNames from 'classnames'
import { ToggleProps } from './index.types'

const AccordionToggle: FC<ToggleProps> = (props) => {
  const { eventKey, callback, className } = props

  const { activeEventKey } = useContext(AccordionContext)

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey))
  const isCurrentEventKey = activeEventKey === eventKey

  return (
    <i
      onClick={decoratedOnClick}
      className={classNames(
        'h2 accordion-icon clickable',
        isCurrentEventKey ? ' uil-angle-up' : 'uil-angle-down',
        className
      )}
      role='button'
    />
  )
}

export default AccordionToggle
