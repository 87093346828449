{
  "nm": "newScene",
  "ddd": 0,
  "h": 150,
  "w": 200,
  "meta": { "g": "@lottiefiles/toolkit-js 0.33.2" },
  "layers": [
    {
      "ty": 0,
      "nm": "loader-source",
      "sr": 1,
      "st": 0,
      "op": 117.000004765508,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [400, 300, 0] },
        "s": { "a": 0, "k": [25, 25, 100] },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [100, 65.88, 0] },
        "r": { "a": 0, "k": 0 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100 }
      },
      "ef": [],
      "w": 800,
      "h": 600,
      "refId": "comp_0",
      "ind": 1
    }
  ],
  "v": "4.5.3",
  "fr": 29.9700012207031,
  "op": 117.000004765508,
  "ip": 0,
  "assets": [
    {
      "nm": "",
      "id": "comp_0",
      "layers": [
        {
          "ty": 4,
          "nm": "Layer 2/loader Outlines",
          "sr": 1,
          "st": -3.00000012219251,
          "op": 43.0000017514259,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": { "a": 0, "k": [106, 105.5, 0] },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": { "x": 0.167, "y": 0 },
                  "i": { "x": 0.207, "y": 0.954 },
                  "s": [0, 0, 100],
                  "t": 0
                },
                { "s": [33, 33, 100], "t": 15.0000006109625 }
              ]
            },
            "sk": { "a": 0, "k": 0 },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": { "x": 0.167, "y": 0.167 },
                  "i": { "x": 0.667, "y": 1 },
                  "s": [400, 337, 0],
                  "t": 15,
                  "ti": [2.25, 3.28968811035156, 0],
                  "to": [0, -0.57204169034958, 0]
                },
                {
                  "o": { "x": 0.31, "y": 0 },
                  "i": { "x": 0.833, "y": 0.833 },
                  "s": [396, 335.04, 0],
                  "t": 26,
                  "ti": [32.75, 5.75, 0],
                  "to": [-1.89180493354797, -2.76597690582275, 0]
                },
                {
                  "o": { "x": 0.322, "y": 0 },
                  "i": { "x": 0.658, "y": 0.366 },
                  "s": [400, 230, 0],
                  "t": 37,
                  "ti": [0.79534405469894, 0.06496899574995, 0],
                  "to": [-0.8968859910965, -0.13742607831955, 0]
                },
                {
                  "o": { "x": 0.356, "y": 0.179 },
                  "i": { "x": 0.749, "y": 0.753 },
                  "s": [397.463, 229.697, 0],
                  "t": 38,
                  "ti": [0, 0, 0],
                  "to": [-26.6949577331543, -2.18062162399292, 0]
                },
                {
                  "o": { "x": 0.167, "y": 0 },
                  "i": { "x": 0.811, "y": 0 },
                  "s": [400, 265, 0],
                  "t": 43,
                  "ti": [0, 0, 0],
                  "to": [0, 0, 0]
                },
                { "s": [470.35, 266.825, 0], "t": 83.0000033806593 }
              ]
            },
            "r": { "a": 0, "k": 0 },
            "sa": { "a": 0, "k": 0 },
            "o": { "a": 0, "k": 100 }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 1",
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 57.99],
                        [57.99, 0],
                        [0, -57.99],
                        [-57.99, 0]
                      ],
                      "o": [
                        [0, -57.99],
                        [-57.99, 0],
                        [0, 57.99],
                        [57.99, 0]
                      ],
                      "v": [
                        [105, 0],
                        [0, -105],
                        [-105, 0],
                        [0, 105]
                      ]
                    }
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.0784, 0.0784, 0.0784] },
                  "r": 2,
                  "o": { "a": 0, "k": 100 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [106, 105.5], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            }
          ],
          "ind": 1
        },
        {
          "ty": 4,
          "nm": "Layer 1/loader Outlines",
          "sr": 1,
          "st": -1.00000004073083,
          "op": 117.000004765508,
          "ip": 43.0000017514259,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": { "a": 0, "k": [105.5, 105.5, 0] },
            "s": { "a": 0, "k": [100, 100, 100] },
            "sk": { "a": 0, "k": 0 },
            "p": { "a": 0, "k": [400, 337.175, 0] },
            "r": {
              "a": 1,
              "k": [
                { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.428, "y": 1 }, "s": [0], "t": 43 },
                { "o": { "x": 0.359, "y": 0 }, "i": { "x": 0.517, "y": 1 }, "s": [90], "t": 58 },
                {
                  "o": { "x": 0.249, "y": 0 },
                  "i": { "x": 0.128, "y": 0.158 },
                  "s": [480],
                  "t": 83
                },
                {
                  "o": { "x": 0.958, "y": 1.387 },
                  "i": { "x": 0.762, "y": -9.662 },
                  "s": [500],
                  "t": 87
                },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 0.833, "y": 1 }, "s": [510], "t": 90 },
                { "s": [900], "t": 106.000004317469 }
              ]
            },
            "sa": { "a": 0, "k": 0 },
            "o": { "a": 0, "k": 100 }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 1",
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 19.33],
                        [19.33, 0],
                        [0, -19.33],
                        [-19.33, 0]
                      ],
                      "o": [
                        [0, -19.33],
                        [-19.33, 0],
                        [0, 19.33],
                        [19.33, 0]
                      ],
                      "v": [
                        [35, 0],
                        [0, -35],
                        [-35, 0],
                        [0, 35]
                      ]
                    }
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.0784, 0.0784, 0.0784] },
                  "r": 2,
                  "o": { "a": 0, "k": 100 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "o": { "x": 0.333, "y": 0 },
                        "i": { "x": 0.667, "y": 1 },
                        "s": [0, 0],
                        "t": 45
                      },
                      {
                        "o": { "x": 0.167, "y": 0.167 },
                        "i": { "x": 0.833, "y": 0.833 },
                        "s": [100, 100],
                        "t": 51
                      },
                      {
                        "o": { "x": 0.167, "y": 0 },
                        "i": { "x": 0.833, "y": 1 },
                        "s": [100, 100],
                        "t": 98
                      },
                      { "s": [0, 0], "t": 104.000004236007 }
                    ],
                    "ix": 3
                  },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [105.5, 175.5], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 2",
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 19.33],
                        [19.33, 0],
                        [0, -19.33],
                        [-19.33, 0]
                      ],
                      "o": [
                        [0, -19.33],
                        [-19.33, 0],
                        [0, 19.33],
                        [19.33, 0]
                      ],
                      "v": [
                        [35, 0],
                        [0, -35],
                        [-35, 0],
                        [0, 35]
                      ]
                    }
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.0784, 0.0784, 0.0784] },
                  "r": 2,
                  "o": { "a": 0, "k": 100 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "o": { "x": 0.167, "y": 0.167 },
                        "i": { "x": 0.833, "y": 0.833 },
                        "s": [100, 100],
                        "t": 43
                      },
                      {
                        "o": { "x": 0.167, "y": 0 },
                        "i": { "x": 0.833, "y": 1 },
                        "s": [100, 100],
                        "t": 94
                      },
                      { "s": [0, 0], "t": 100.000004073084 }
                    ],
                    "ix": 3
                  },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [105.5, 35.5], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 3",
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 19.33],
                        [19.33, 0],
                        [0, -19.33],
                        [-19.33, 0]
                      ],
                      "o": [
                        [0, -19.33],
                        [-19.33, 0],
                        [0, 19.33],
                        [19.33, 0]
                      ],
                      "v": [
                        [35, 0],
                        [0, -35],
                        [-35, 0],
                        [0, 35]
                      ]
                    }
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.0784, 0.0784, 0.0784] },
                  "r": 2,
                  "o": { "a": 0, "k": 100 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "o": { "x": 0.333, "y": 0 },
                        "i": { "x": 0.667, "y": 1 },
                        "s": [0, 0],
                        "t": 47
                      },
                      {
                        "o": { "x": 0.167, "y": 0.167 },
                        "i": { "x": 0.833, "y": 0.833 },
                        "s": [100, 100],
                        "t": 53
                      },
                      {
                        "o": { "x": 0.167, "y": 0 },
                        "i": { "x": 0.833, "y": 1 },
                        "s": [100, 100],
                        "t": 100
                      },
                      { "s": [0, 0], "t": 106.000004317469 }
                    ],
                    "ix": 3
                  },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [35.5, 105.5], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 4",
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 19.33],
                        [19.33, 0],
                        [0, -19.33],
                        [-19.33, 0]
                      ],
                      "o": [
                        [0, -19.33],
                        [-19.33, 0],
                        [0, 19.33],
                        [19.33, 0]
                      ],
                      "v": [
                        [35, 0],
                        [0, -35],
                        [-35, 0],
                        [0, 35]
                      ]
                    }
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.0784, 0.0784, 0.0784] },
                  "r": 2,
                  "o": { "a": 0, "k": 100 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "o": { "x": 0.333, "y": 0 },
                        "i": { "x": 0.667, "y": 1 },
                        "s": [0, 0],
                        "t": 43
                      },
                      {
                        "o": { "x": 0.167, "y": 0.167 },
                        "i": { "x": 0.833, "y": 0.833 },
                        "s": [100, 100],
                        "t": 49
                      },
                      {
                        "o": { "x": 0.167, "y": 0 },
                        "i": { "x": 0.833, "y": 1 },
                        "s": [100, 100],
                        "t": 96
                      },
                      { "s": [0, 0], "t": 102.000004154545 }
                    ],
                    "ix": 3
                  },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [175.5, 105.5], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            }
          ],
          "ind": 2
        }
      ]
    }
  ]
}
