import { createPath } from './path-utils'
import { PathGenerator } from './path.types'

const PROFILE_PATH = '/account'

export const profilePath: PathGenerator = () => {
  return createPath(PROFILE_PATH)
}

export const profileLoginPath: PathGenerator = () => {
  return createPath(PROFILE_PATH, {
    suffix: 'login',
  })
}

export const profileLogoutPath: PathGenerator = () => {
  return createPath(PROFILE_PATH, { suffix: 'logout' })
}

export const profileRegisterPath: PathGenerator = () => {
  return createPath(PROFILE_PATH, { suffix: 'register' })
}

export const profileVerifEmailPath: PathGenerator = () => {
  return createPath(PROFILE_PATH, { suffix: 'verification-email' })
}

export const profileForgotPasswordPath: PathGenerator = () => {
  return createPath(PROFILE_PATH, { suffix: 'forgot-password' })
}

export const profileVerifResetPassword: PathGenerator = () => {
  return createPath(PROFILE_PATH, { suffix: 'verify-reset-password' })
}

export const profileResetPassword: PathGenerator = () => {
  return createPath(PROFILE_PATH, { suffix: 'reset-password' })
}
