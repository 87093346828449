/* eslint-disable array-callback-return */
// @ts-nocheck
import { Link } from 'react-router-dom'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FileUploaderProps } from './file-uploader.types'
import useFileUploader from './use-file-uploader'

const maxSize = 2097152 // 2MB

export const FileUploader = ({
  showPreview = true,
  acceptedFile = 'image/jpeg,image/png,image/gif,image/jpg',
  onFileUpload,
  onFileRemove,
  hint,
  isInvalid,
  value,
  maxFile = 1,
}: FileUploaderProps) => {
  const { handleAcceptedFiles } = useFileUploader(showPreview, onFileUpload)

  const { t } = useTranslation()

  return (
    <>
      <Dropzone
        maxFiles={maxFile}
        accept={acceptedFile}
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
        maxSize={maxSize}
      >
        {({ getRootProps, getInputProps, fileRejections }) => {
          const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize
          const isTooManyFiles = fileRejections.length > 0 && fileRejections.length > maxFile

          return (
            <div className={`dropzone center ${isInvalid ? 'border-danger' : 'text-charcoal-3'}`}>
              <div className='dz-message needsclick' {...getRootProps()}>
                <input {...getInputProps()} />
                <i
                  className={`h1 icon-upload text-primary-blue ${isInvalid ? 'text-danger' : ''}`}
                />
                <p className='text-black mb-0'>{t('Drop files here or click to upload.')}</p>
                {hint && <Form.Text muted>{hint}</Form.Text>}
                {isFileTooLarge && (
                  <div className='text-danger mt-2 fw-400'>{t('File too large')}</div>
                )}
                {isTooManyFiles && (
                  <div className='text-danger mt-2 fw-400'>{t('Too many files')}</div>
                )}
              </div>
            </div>
          )
        }}
      </Dropzone>
      {showPreview && Array.isArray(value) && (
        <>
          {value.map((f, i) => {
            if (f?.image) {
              return (
                <div className='dropzone-previews mt-3' id='uploadPreviewTemplate' key={i}>
                  <Card className='mt-1 mb-0 shadow-none border' key={i + '-file'}>
                    <div className='p-2'>
                      <Row className='align-items-center'>
                        {f?.image?.preview && (
                          <Col className='col-auto'>
                            <img
                              data-dz-thumbnail=''
                              className='avatar-sm rounded bg-light'
                              alt={f?.name}
                              src={f?.image?.preview}
                            />
                          </Col>
                        )}
                        {!f?.image?.preview && f?.image?.type && (
                          <Col className='col-auto'>
                            <div className='avatar-sm'>
                              <span className='avatar-title bg-primary rounded'>
                                {f?.image?.type.split('/')[0]}
                              </span>
                            </div>
                          </Col>
                        )}
                        <Col className='ps-0'>
                          {f?.name && (
                            <Link to='#' className='text-muted fw-bold'>
                              {f?.name}
                            </Link>
                          )}
                          {f?.image?.formattedSize && (
                            <p className='mb-0'>
                              <strong>{f?.image?.formattedSize}</strong>
                            </p>
                          )}
                        </Col>
                        <Col className='text-end'>
                          <Button
                            variant='link'
                            className='btn btn-link btn-lg text-muted shadow-none'
                            onClick={() => onFileRemove(f)}
                          >
                            <i className='mdi mdi-close'></i>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              )
            }
          })}
        </>
      )}
    </>
  )
}
