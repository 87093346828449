import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import { logoBanner, logoMini } from 'assets/images'

import Menu from './menu/menu'
import { getMenuItems } from './helpers/helpers'
import { UserType } from 'types/global.types'

type LeftSidebarProps = {
  hideLogo?: boolean
  hideUserProfile?: boolean
  isLight: boolean
  isCondensed?: boolean
  userType: UserType
}

const LeftSidebar = ({ hideLogo, userType, isCondensed }: LeftSidebarProps) => {
  const menuNodeRef = useRef<HTMLDivElement>(null)

  /**
   * Handle the click anywhere in doc
   */
  const handleOtherClick = (e: MouseEvent) => {
    if (menuNodeRef && menuNodeRef.current && menuNodeRef.current.contains(e.target as Node)) return
    // else hide the menubar
    if (document.body) {
      document.body.classList.remove('sidebar-enable')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOtherClick, false)

    return () => {
      document.removeEventListener('mousedown', handleOtherClick, false)
    }
  }, [])

  return (
    <div className='leftside-menu' ref={menuNodeRef}>
      {!hideLogo && (
        <>
          <Link to='/' className='logo text-center logo-light pb-2'>
            <span className='logo-lg'>
              <img src={logoBanner} alt='logo' height='24' />
            </span>
            <span className='logo-sm'>
              <img src={logoMini} alt='logo' height='38' />
            </span>
          </Link>
        </>
      )}

      {!isCondensed && (
        <>
          <Menu menuItems={getMenuItems(userType)} />
          <div className='clearfix' />
        </>
      )}

      {isCondensed && (
        <>
          <Menu menuItems={getMenuItems(userType)} />
          <div className='clearfix' />
        </>
      )}
    </div>
  )
}

export default LeftSidebar
