import React, { FC } from 'react'
import { IconProps } from './index.types'

import classNames from 'classnames'

const Icon: FC<IconProps> = (props) => {
  const { icon, size = '24', className } = props

  return (
    <i
      className={classNames('icon', icon, className)}
      style={size ? { fontSize: `${size}px`, width: `${size}px` } : {}}
    />
  )
}

export default Icon
