import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface ApprovalComponentProps {
  status: string
}

export const ApprovalStatus: FC<ApprovalComponentProps> = ({ status }) => {
  const { t } = useTranslation()

  const statusElement = useMemo(() => {
    switch (status) {
      case 'approved':
        return (
          <div className='status-card bg-secondary-green rounded d-flex justify-content-center'>
            <p className='status-title m-0 fw-bold text-primary-green'>{t('Approved')}</p>
          </div>
        )
      case 'pending':
        return (
          <div className='status-card bg-secondary-yellow rounded  d-flex justify-content-center'>
            <p className='status-title m-0 fw-bold text-primary-yellow'>{t('Pending')}</p>
          </div>
        )
      case 'rejected':
        return (
          <div className='status-card bg-secondary-red rounded  d-flex justify-content-center'>
            <p className='status-title m-0 fw-bold text-primary-red'>{t('Rejected')}</p>
          </div>
        )
      default:
        return (
          <div className='status-card bg-secondary-yellow rounded  d-flex justify-content-center'>
            <p className='status-title m-0 fw-bold text-primary-yellow'>{t('Pending')}</p>
          </div>
        )
    }
  }, [status])

  return statusElement
}
