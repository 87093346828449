import { Instance, SnapshotOut, applySnapshot, types } from 'mobx-state-tree'

import { ToastModel } from './toast'

export const ToastStoreModel = types.snapshotProcessor(
  types
    .model('ToastStore')
    .props({
      basic: types.optional(ToastModel, {}),
      stacked: types.array(ToastModel),
    })
    .actions((self) => ({
      showBasicToast(toast: any) {
        self.basic = toast
      },
      showStackedToast(toast: any) {
        self.stacked.push(toast)
      },
      cleanStackedToast() {
        applySnapshot(self.stacked, [...self.stacked.filter((item) => item.visible)])
      },
    })),
  {
    preProcessor: () => {
      return {}
    },
  }
)

export type ToastStore = Instance<typeof ToastStoreModel>
export type ToastStoreSnapshot = SnapshotOut<typeof ToastStoreModel>
