import { TFunction } from 'react-i18next'
import * as yup from 'yup'

export const loginScheme = (t: TFunction) => {
  return yup.object().shape({
    email: yup
      .string()
      .email(t('Please enter a valid email'))
      .required(t('Please enter your email')),
    password: yup.string().required(t('Please enter your password')),
  })
}
