import { useToast } from 'hooks/use-toast.hook'
import { capitalize } from 'lodash'
import { useStores } from 'models/helpers/use-stores'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useAuth = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const {
    authStore: { email, register, verifyEmailToken, resendVerifyEmail, addPersonalInformation },
  } = useStores()

  const { showToast } = useToast()

  const handleRegister = async (data: any) => {
    try {
      setIsLoading(true)
      const ok = await register(data)
      showToast({
        variant: 'success',
        body: t('Register Successful. Please check email for verification'),
      })

      return ok
    } catch (error) {
      showToast({
        variant: 'danger',
        body: capitalize(error?.data?.errors),
        title: t('Failed to register account!'),
      })

      return false
    } finally {
      setIsLoading(false)
    }
  }

  const handleVerifyEmail = async (data: any) => {
    try {
      setIsLoading(true)
      return await verifyEmailToken({ email, ...data })
    } catch (error) {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const handleResendVerifyEmail = async () => {
    try {
      setIsLoading(true)
      return await resendVerifyEmail({ email })
    } catch (error) {
      showToast({
        variant: 'danger',
        body: capitalize(error?.data?.errors),
        title: t('Failed to resend verify this email!'),
      })

      return false
    } finally {
      setIsLoading(false)
    }
  }

  const handleAddPersonalInformation = async <T>(data: T) => {
    try {
      setIsLoading(true)
      await addPersonalInformation(data)
      showToast({
        variant: 'success',
        body: t('Your account is successfully created'),
        title: t('Success'),
      })
    } catch (error) {
      showToast({
        variant: 'danger',
        body: capitalize(error?.data?.errors),
        title: t('Failed to add personal information!'),
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    handleRegister,
    handleVerifyEmail,
    handleResendVerifyEmail,
    handleAddPersonalInformation,
  }
}
