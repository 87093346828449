import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const TutorialsModel = types.model('Tutorials').props({
  id: types.frozen(),
  information_type: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  index: types.optional(types.maybeNull(types.string), '0'),
  type: types.optional(types.maybeNull(types.string), ''),
})

export type TutorialsInstance = Instance<typeof TutorialsModel>
export type TutorialsType = Omit<TutorialsInstance, symbol>
export type TutorialsSnapshot = SnapshotOut<typeof TutorialsModel>
