import React, { InputHTMLAttributes } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import classNames from 'classnames'
import { FieldErrors, Control } from 'react-hook-form'
import { useToggle } from 'hooks'

type PasswordInputProps = {
  name: string
  placeholder?: string
  refCallback?: any
  errors: FieldErrors
  control?: Control<any>
  register?: any
  className?: string
}

/* Password Input */
const PasswordInput = ({
  name,
  placeholder,
  refCallback,
  errors,
  register,
  className,
}: PasswordInputProps) => {
  const [showPassword, togglePassword] = useToggle()

  return (
    <InputGroup className='mb-0'>
      <Form.Control
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        name={name}
        id={name}
        as='input'
        ref={(r: HTMLInputElement) => {
          if (refCallback) refCallback(r)
        }}
        className={className}
        isInvalid={!!(errors && errors[name])}
        {...(register ? register(name) : {})}
        autoComplete={name}
      />
      <div
        className={classNames('input-group-text', 'input-group-password', {
          'show-password': showPassword,
        })}
        data-password={showPassword ? 'true' : 'false'}
      >
        <span className='password-eye' onClick={togglePassword}></span>
      </div>
    </InputGroup>
  )
}

type FormInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  type?: string
  name: string
  placeholder?: string
  register?: any
  errors?: FieldErrors
  control?: Control<any>
  className?: string
  labelClassName?: string
  containerClass?: string
  refCallback?: any
  children?: React.ReactNode
  rows?: string
}

export const FormInput = ({
  label,
  type,
  name,
  placeholder,
  register,
  errors,
  className,
  labelClassName,
  containerClass,
  refCallback,
  children,
  ...otherProps
}: FormInputProps) => {
  // handle input type
  const comp = type === 'textarea' ? 'textarea' : type === 'select' ? 'select' : 'input'

  const renderInput = () => {
    switch (type) {
      case 'hidden':
        return (
          <input type={type} name={name} {...(register ? register(name) : {})} {...otherProps} />
        )

      case 'password':
        return (
          <Form.Group className={containerClass}>
            {label && (
              <>
                {' '}
                <Form.Label className={labelClassName}>{label}</Form.Label>
                {children}{' '}
              </>
            )}

            <PasswordInput
              name={name}
              placeholder={placeholder}
              refCallback={refCallback}
              errors={errors!}
              register={register}
              className={className}
            />

            {errors && errors[name] && (
              <Form.Control.Feedback type='invalid' className='d-block'>
                {errors[name].message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )

      case 'select':
        return (
          <Form.Group className={containerClass}>
            {label && <Form.Label className={labelClassName}>{label}</Form.Label>}

            <Form.Select
              type={type}
              label={label}
              name={name}
              id={name}
              ref={(r: HTMLInputElement) => {
                if (refCallback) refCallback(r)
              }}
              comp={comp}
              className={className}
              isInvalid={!!(errors && errors[name])}
              {...(register ? register(name) : {})}
              {...otherProps}
            >
              {children}
            </Form.Select>

            {errors && errors[name] && (
              <Form.Control.Feedback type='invalid'>{errors[name].message}</Form.Control.Feedback>
            )}
          </Form.Group>
        )

      case 'checkbox' || 'radio':
        return (
          <Form.Group className={containerClass}>
            <Form.Check
              type={type}
              label={label}
              name={name}
              id={name}
              ref={(r: HTMLInputElement) => {
                if (refCallback) refCallback(r)
              }}
              className={className}
              isInvalid={!!(errors && errors[name])}
              {...(register ? register(name) : {})}
              {...otherProps}
            />

            {errors && errors[name] && (
              <Form.Control.Feedback type='invalid'>{errors[name].message}</Form.Control.Feedback>
            )}
          </Form.Group>
        )

      default:
        return (
          <Form.Group className={containerClass}>
            {label && <Form.Label className={labelClassName}>{label}</Form.Label>}

            <Form.Control
              type={type}
              placeholder={placeholder}
              name={name}
              id={name}
              as={comp}
              ref={(r: HTMLInputElement) => {
                if (refCallback) refCallback(r)
              }}
              className={className}
              isInvalid={!!(errors && errors[name])}
              {...(register ? register(name) : {})}
              {...otherProps}
              autoComplete={name}
            >
              {children || null}
            </Form.Control>

            {errors && errors[name] && (
              <Form.Control.Feedback type='invalid'>{errors[name].message}</Form.Control.Feedback>
            )}
          </Form.Group>
        )
    }
  }

  return renderInput()
}
