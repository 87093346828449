import React from 'react'
import { Form, FormControlProps } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'

type InputProps = FormControlProps & {
  label?: string
  name: string
  containerClass?: string
  step?: number
  min?: number
  max?: number
  required?: boolean
}

export const ControlledInput = (props: InputProps) => {
  const { label, name, containerClass, children, step, required, ...otherProps } = props

  const { register, control } = useFormContext()
  const {
    fieldState: { invalid, error },
  } = useController({ control, name })

  return (
    <Form.Group className={containerClass}>
      <div className='d-flex gap-1'>
        {label && <Form.Label>{label}</Form.Label>}
        {required && <span className='text-primary'>*</span>}
      </div>
      <Form.Control
        name={name}
        id={name}
        as='input'
        isInvalid={invalid}
        step={step}
        {...register(name)}
        {...otherProps}
        autoComplete={name}
      >
        {children}
      </Form.Control>
      {error && (
        <Form.Control.Feedback type='invalid' className='fw-semibold'>
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
