import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Pagination as RBPagination, Row, Col } from 'react-bootstrap'

import { useViewport } from 'hooks'

import { PaginationProps } from './table.types'
import { useSearchParams } from 'react-router-dom'
import { getPaginationFromSearchParams } from './table.helper'

export const Pagination = <TableValues extends Record<string, any>>(
  props: PaginationProps<TableValues>
) => {
  const { table, currentPage = 1, totalPage = 7, isLists } = props

  const { width } = useViewport()

  const filterPages = useCallback(
    (visiblePages: number[]) => {
      return visiblePages.filter((page: number) => page <= totalPage)
    },
    [totalPage]
  )

  const getVisiblePages = useCallback(
    (page: number, total: number) => {
      if (width < 576) return []

      if (total < 7) {
        return filterPages([1, 2, 3, 4, 5, 6])
      } else {
        if (page % 5 >= 0 && page > 4 && page + 2 < total) {
          return [1, page - 1, page, page + 1, total]
        } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
          return [1, total - 3, total - 2, total - 1, total]
        } else {
          return [1, 2, 3, 4, 5, total]
        }
      }
    },
    [filterPages, width]
  )

  const [visiblePages, setVisiblePages] = useState<number[]>(
    getVisiblePages(currentPage, totalPage)
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const pageActive = searchParams.get('page') || 1

  const pagination = useMemo(() => getPaginationFromSearchParams(searchParams), [searchParams])

  const handleDisabledPrev = isLists ? pageActive === '1' : !table.getCanPreviousPage()

  const handleDisabledNext = isLists
    ? totalPage === Number(pageActive)
    : (!table.getCanNextPage() as boolean)

  const setPagination = ({ isBack, initialPage }: { isBack?: boolean; initialPage?: number }) => {
    const params = Object.fromEntries(searchParams)

    const { pageSize } = pagination

    if (initialPage) params.page = `${initialPage}`
    else params.page = isBack ? `${Number(pageActive) - 1}` : `${Number(pageActive) + 1}`
    params.perPage = `${pageSize}`

    setSearchParams(params)
  }

  const handleBack = () => {
    if (isLists) {
      setPagination({
        isBack: true,
      })
    } else table.previousPage()
  }

  const handleNext = () => {
    if (isLists) setPagination({})
    else table.nextPage()
  }

  const handleActivePage = (page: any) => {
    if (isLists) {
      setPagination({
        isBack: false,
        initialPage: page,
      })
    } else table.setPageIndex(page - 1)
  }

  useEffect(() => {
    const visiblePages = getVisiblePages(currentPage, totalPage)

    setVisiblePages(visiblePages)
  }, [currentPage, totalPage, getVisiblePages])

  return (
    <Row className='mt-2 ms-0'>
      {totalPage > 1 && (
        <Col xs={12} lg={12} className='table-pagination-numbers'>
          <RBPagination className='mb-0' {...(width < 576 && { size: 'lg' })}>
            <RBPagination.Prev disabled={handleDisabledPrev} onClick={handleBack}>
              <i className='uil uil-angle-double-left font-24' />
            </RBPagination.Prev>

            {(visiblePages || []).map((page, index, array) => {
              return array[index - 1] + 1 < page ? (
                <React.Fragment key={page.toString()}>
                  <RBPagination.Item>...</RBPagination.Item>
                  <RBPagination.Item
                    active={page === currentPage}
                    onClick={() => handleActivePage(page)}
                  >
                    {page}
                  </RBPagination.Item>
                </React.Fragment>
              ) : (
                <RBPagination.Item
                  key={page.toString()}
                  active={page === currentPage}
                  onClick={() => handleActivePage(page)}
                >
                  {page}
                </RBPagination.Item>
              )
            })}

            <RBPagination.Next disabled={handleDisabledNext} onClick={handleNext}>
              <i className='uil uil-angle-double-right font-24' />
            </RBPagination.Next>
          </RBPagination>
        </Col>
      )}
    </Row>
  )
}
