import {
  adminFinancialPath,
  adminFormPath,
  adminReportingPath,
  userDetailsPath,
  userListManagementPath,
} from 'config/paths'
import {
  contentManagementEditPath,
  contentManagementPath,
} from 'config/paths/content-management-path'

import {
  eventDetailPath,
  eventsDetailsManagementPath,
  eventsManagementListParticipantPath,
  eventsManagementListPath,
} from 'config/paths/event-path'
import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { LoadComponent } from 'routes/load-component'

const userList = lazy(() => import('pages/super-admin/user-management/user-table'))

const contentManagement = lazy(() => import('pages/super-admin/content-management'))

const formAdmin = lazy(() => import('pages/super-admin/user-management/add-admin'))

const userDetails = lazy(() => import('pages/super-admin/user-management/user-details'))

const eventListManagement = lazy(() => import('pages/super-admin/event-management/table-event'))

const event = lazy(() => import('pages/creator/event'))

const eventDetails = lazy(() => import('pages/super-admin/event-management/event-details'))

const eventListParticipant = lazy(
  () => import('pages/super-admin/event-management/manage-participant')
)

const adminReportAndAnalytics = lazy(() => import('pages/super-admin/report-and-analytic'))

const adminFinancial = lazy(() => import('pages/super-admin/financial-management'))

const superAdminRoutes = (): RouteObject[] => [
  {
    path: userListManagementPath(),
    element: <LoadComponent component={userList} />,
  },
  {
    path: contentManagementPath(),
    element: <LoadComponent component={contentManagement} />,
  },
  {
    path: contentManagementPath(':id'),
    element: <LoadComponent component={contentManagement} />,
  },
  {
    path: contentManagementEditPath(':id'),
    element: <LoadComponent component={contentManagement} />,
  },
  {
    path: adminFormPath(),
    element: <LoadComponent component={formAdmin} />,
  },
  {
    path: userDetailsPath(':id'),
    element: <LoadComponent component={userDetails} />,
  },
  {
    path: eventDetailPath(':id'),
    element: <LoadComponent component={event} />,
  },
  {
    path: eventsManagementListPath(),
    element: <LoadComponent component={eventListManagement} />,
  },
  {
    path: eventsDetailsManagementPath(':id'),
    element: <LoadComponent component={eventDetails} />,
  },
  {
    path: eventsManagementListParticipantPath(':id'),
    element: <LoadComponent component={eventListParticipant} />,
  },
  {
    path: adminReportingPath(),
    element: <LoadComponent component={adminReportAndAnalytics} />,
  },
  {
    path: adminFinancialPath(),
    element: <LoadComponent component={adminFinancial} />,
  },
  {
    path: adminFinancialPath(':id'),
    element: <LoadComponent component={adminFinancial} />,
  },
]

export { superAdminRoutes }
