import { createPath } from './path-utils'
import { PathGenerator, PathGeneratorWithId, PathGeneratorWithParams } from './path.types'

const EVENT_PATH = '/event'

export const eventDetailPath: PathGeneratorWithId = (id) => {
  return createPath(EVENT_PATH, id)
}

export const eventsManagementListPath: PathGenerator = () => {
  return createPath(EVENT_PATH, { suffix: 'list' })
}

export const eventsManagementListParticipantPath: PathGeneratorWithParams = (id) => {
  return createPath(EVENT_PATH, {
    id,
    suffix: 'participants/list',
  })
}

export const eventsDetailsManagementPath: PathGeneratorWithId = (id) => {
  return createPath(EVENT_PATH, {
    id,
    suffix: 'manage-details',
  })
}
