import React from 'react'
import { useTranslation } from 'react-i18next'

interface TaskComponentProps {
  taskCategory: string
}

export const TaskCategory = ({ taskCategory }: TaskComponentProps) => {
  const { t } = useTranslation()

  switch (taskCategory) {
    case 'health':
      return (
        <div>
          <p className='status-title bg-primary-blue mb-0 px-1'>{t('Health')}</p>
        </div>
      )
    case 'sport':
      return (
        <div>
          <p className='status-title bg-primary-red mb-0 px-1'>{t('Sport')}</p>
        </div>
      )
    case 'wellness':
      return (
        <div>
          <p className='status-title bg-primary-yellow mb-0 px-1'>{t('Wellness')}</p>
        </div>
      )
    case 'food':
      return (
        <div>
          <p className='status-title bg-primary-green mb-0 px-1'>{t('Food')}</p>
        </div>
      )
    default:
      return <div></div>
  }
}
