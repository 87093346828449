import { createPath } from './path-utils'
import { PathGenerator, PathGeneratorWithId, PathGeneratorWithParams } from './path.types'

const CHALLENGES_PATH = '/challenges'

export const challengesPath: PathGeneratorWithId = (id) => {
  return createPath(CHALLENGES_PATH, id)
}

export const challengesEditPath: PathGeneratorWithParams = (id) => {
  return createPath(CHALLENGES_PATH, {
    id,
    suffix: 'edit',
  })
}

export const challengesFormPath: PathGenerator = () => {
  return createPath(CHALLENGES_PATH, { suffix: 'form' })
}

export const challengesPreviewPath: PathGenerator = () => {
  return createPath(CHALLENGES_PATH, {
    suffix: 'preview',
  })
}

export const challengesEditPreviewPath: PathGeneratorWithParams = (id) => {
  return createPath(CHALLENGES_PATH, {
    id,
    suffix: 'edit/preview',
  })
}
