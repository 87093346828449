import React from 'react'
import classNames from 'classnames'
import Icon from 'components/icon'

type PageTitleProps = {
  textClass?: string
  icon?: string
  title?: string
}

export const PageTitle = ({ textClass, icon, title }: PageTitleProps) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='tittle-icon'>{icon && <Icon icon={icon} size={17} />}</div>
      <h4 className={classNames('fw-bold text-black ms-1 my-1', textClass)}>{title}</h4>
    </div>
  )
}
