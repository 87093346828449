import React, { FunctionComponent } from 'react'
import { Alert, Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { FormInput, SubmitButton, VerticalForm } from 'components'

import useLogin from './login.hook'
import { logoBannerWhite } from 'assets/images'
import { Link } from 'react-router-dom'
import Image from 'components/image'

const Login: FunctionComponent = observer(() => {
  const { t } = useTranslation()

  const { isLoading, loginResolver, doLogin, error } = useLogin()

  return (
    <>
      <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5} xxl={4}>
              <Card>
                <Card.Header className='py-3 text-center bg-black'>
                  <Link to='/'>
                    <Image image={logoBannerWhite} height={24} width={100} />
                  </Link>
                </Card.Header>
                <Card.Body className='px-4 pt-4 pb-2'>
                  <div className='m-auto text-center'>
                    <h4 className='text-black  fw-bold mt-0 font-18'>{t('Login')}</h4>
                    <p className='text-neutral-4 fw-semibold font-14 p-0 m-0'>
                      {t('Enter your email address and password to')}
                    </p>
                    <p className='mt-0 mx-0 p-0'>{t('access the admin panel')}</p>
                  </div>
                  {error && (
                    <Alert variant='danger' className='my-2'>
                      {error}
                    </Alert>
                  )}
                  <VerticalForm onSubmit={doLogin} resolver={loginResolver}>
                    <FormInput
                      label={t('Email')}
                      type='text'
                      name='email'
                      placeholder={t('Your email')}
                      containerClass={'mb-3'}
                    />
                    <FormInput
                      label={t('Password')}
                      type='password'
                      name='password'
                      placeholder={t('Your password')}
                      containerClass='mb-1'
                    />
                    <div className='mb-3 text-end'>
                      <Link to='/account/forgot-password' className='text-primary-blue fw-600'>
                        {t('Forgot Password ?')}
                      </Link>
                    </div>
                    <div className='d-flex justify-content-center mb-3'>
                      <SubmitButton variant='primary' type='submit' size='lg' loading={isLoading}>
                        {t('Login')}
                      </SubmitButton>
                    </div>
                    <div className='text-center m-0'>
                      <p>
                        {t('Didn’t have an account ? ')}
                        <Link to='/account/register' className='text-primary-blue fw-600'>
                          {t('Register here')}
                        </Link>
                      </p>
                    </div>
                  </VerticalForm>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
})

export default Login
