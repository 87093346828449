import { Loader } from 'components'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import useLogout from './logout.hook'

const Logout: FunctionComponent = observer(() => {
  const { loggedOut } = useLogout()

  if (!loggedOut) return null

  return <Loader />
})

export default Logout
