/* eslint-disable no-unused-vars */
import { ApiResponse } from 'apisauce'

import { ApiError } from './api-core.types'

export enum ApiErrorKind {
  TIMEOUT = 'timeout',
  CONNECTION = 'cannot-connect',
  SERVER = 'server',
  UNAUTHORIZED = 'unauthorized',
  FORBIDDEN = 'forbidden',
  NOT_FOUND = 'not-found',
  GONE = 'gone',
  UNPROCESSABLE = 'unprocessable-entity',
  REJECTED = 'rejected',
  UNKNOWN = 'unknown',
  BAD_DATA = 'bad-data',
  BAD_REQUEST = 'bad-request',
}

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: ApiErrorKind.TIMEOUT; temporary: true }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: ApiErrorKind.CONNECTION; temporary: true }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: ApiErrorKind.SERVER }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: ApiErrorKind.UNAUTHORIZED; message?: string }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: ApiErrorKind.FORBIDDEN }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: ApiErrorKind.NOT_FOUND; data?: ApiError }
  /**
   * Unable to find that resource.  This is a 410.
   */
  | { kind: ApiErrorKind.GONE }
  /**
   * The data we sent is not valid.  This is a 422.
   */
  | { kind: ApiErrorKind.UNPROCESSABLE; data?: ApiError }
  /**
   * All other 4xx series errors.
   */
  | { kind: ApiErrorKind.REJECTED; data?: ApiError }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: ApiErrorKind.UNKNOWN; temporary: true }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: ApiErrorKind.BAD_DATA }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: ApiErrorKind.BAD_REQUEST; data?: ApiError }

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(response: ApiResponse<any>): GeneralApiProblem | void | null {
  switch (response.problem) {
    case 'CONNECTION_ERROR':
      return { kind: ApiErrorKind.CONNECTION, temporary: true }
    case 'NETWORK_ERROR':
      return { kind: ApiErrorKind.CONNECTION, temporary: true }
    case 'TIMEOUT_ERROR':
      return { kind: ApiErrorKind.TIMEOUT, temporary: true }
    case 'SERVER_ERROR':
      return { kind: ApiErrorKind.SERVER }
    case 'UNKNOWN_ERROR':
      return { kind: ApiErrorKind.UNKNOWN, temporary: true }
    case 'CLIENT_ERROR':
      switch (response.status) {
        case 401:
          return { kind: ApiErrorKind.UNAUTHORIZED, message: response.data }
        case 403:
          return { kind: ApiErrorKind.FORBIDDEN }
        case 404:
          return { kind: ApiErrorKind.NOT_FOUND, data: response.data }
        case 422:
          return { kind: ApiErrorKind.UNPROCESSABLE, data: response.data }
        case 400:
          return { kind: ApiErrorKind.BAD_REQUEST, data: response.data }
        default:
          return { kind: ApiErrorKind.REJECTED, data: response.data }
      }
    case 'CANCEL_ERROR':
    default:
      return null
  }
}
