import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { useStores } from 'models'
import { useAuth } from 'models/hooks/use-auth'

export default function useRegister() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [isAble, setIsAble] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [error, setError] = useState(null)

  const {
    profileStore: { getGeneralInformation, generalInfo },
  } = useStores()

  const { isLoading, handleRegister } = useAuth()

  const registerResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .email(t('Please enter a valid email'))
        .required(t('Please enter your email')),
      password: yup.string().required(t('Please enter your password')).min(8, 'Minimum 8 chars'),
      confirmPassword: yup
        .string()
        .required(t('Please re-enter your password'))
        .oneOf([yup.ref('password'), null], "Password doesn't match"),
    })
  )

  const navigateToVerify = () => {
    navigate('/verification-email')
  }

  const handleBack = () => {
    sessionStorage.clear()
  }

  const onRegister = async (data: any) => {
    setError(null)

    const ok = await handleRegister(data)
    if (!ok) return setError(t('Email has already registered!'))
    navigateToVerify()
  }

  useEffect(() => {
    getGeneralInformation(3)
  }, [])

  return {
    isLoading,
    generalInfo,
    error,
    isAble,
    isShowModal,
    registerResolver,
    onRegister,
    handleBack,
    setIsAble,
    setIsShowModal,
  }
}
