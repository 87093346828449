import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import { translate as t } from 'i18n'

export const ToastModel = types
  .model('Toast')
  .props({
    variant: types.optional(types.enumeration(['success', 'danger', 'warning', 'info']), 'success'),
    title: types.maybeNull(types.string),
    body: types.maybeNull(types.string),
  })
  .views((self) => ({
    get displayTitle() {
      return self.title || t(self.variant)
    },
    get visible() {
      return !!self.body
    },
    get icon() {
      switch (self.variant) {
        case 'danger':
          return 'mdi mdi-close-circle'
        case 'warning':
          return 'mdi mdi-alert-outline'
        case 'info':
          return 'mdi mdi-web'
        case 'success':
        default:
          return 'mdi mdi-check'
      }
    },
  }))
  .actions((self) => ({
    hide() {
      self.body = null
    },
  }))

export type ToastInstance = Instance<typeof ToastModel>
export type Toast = Omit<ToastInstance, symbol>
export type ToastSnapshot = SnapshotOut<typeof ToastModel>
