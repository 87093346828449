import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const FormEventCompletedModel = types.model('FormEventCompleted').props({
  details: types.boolean,
  media: types.boolean,
  price: types.boolean,
  tasks: types.boolean,
  safetyGuidelines: types.boolean,
})

export type FormEventCompletedInstance = Instance<typeof FormEventCompletedModel>
export type FormEventCompletedType = Omit<FormEventCompletedInstance, symbol>
export type FormEventCompletedSnapshot = SnapshotOut<typeof FormEventCompletedModel>
