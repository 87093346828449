import startCase from 'lodash/startCase'
import { UseFormReturn } from 'react-hook-form'

import { ApiError } from 'services/api'
import { delay } from 'utils/delay'

type ParseApiErrorParams = {
  error: ApiError
  methods: UseFormReturn<any, any>
  keysMapping?: Record<string, string>
}

const createErrorKey = (key: string, keysMapping: Record<string, string>) => {
  const mappingKeys = Object.keys(keysMapping)
  const keyIncluded = mappingKeys.filter((item) => key.startsWith(item))

  let errorKey = key

  if (keyIncluded.length) {
    errorKey = key
      .replace(keyIncluded[0], keysMapping[keyIncluded[0]])
      .replace(/^(\.)/, '')
      .replace(/(\.)$/, '')
  }

  return errorKey
}

const createErrorMessage = (key: string, messages: any[]) => {
  const splittedKey = key.replace('[', '.').replace(']', '').split('.')
  const fieldName = splittedKey[splittedKey.length - 1]

  return `${startCase(fieldName)} ${messages[0]}`
}

const scrollToFirstErrorField = async () => {
  await delay(150)
  document.getElementsByClassName('invalid-feedback')[0]?.scrollIntoView(false)
}

export const parseApiErrors = (params: ParseApiErrorParams) => {
  const { error, methods, keysMapping = {} } = params

  if (!error?.detail) return

  Object.entries(error.detail).forEach(([key, messages]) => {
    const errorKey = createErrorKey(key, keysMapping)
    const errorMessage = createErrorMessage(key, messages)

    if (!errorKey) return

    methods.setError(errorKey, { message: errorMessage, type: 'custom' }, { shouldFocus: false })
  })

  scrollToFirstErrorField()
}
