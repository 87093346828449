import i18n from 'i18next'
import ICU from 'i18next-icu'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { englishTranslation } from 'locales'

// translations
const resources = {
  en: englishTranslation,
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(ICU)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export function translate(key: string, options?: object) {
  return key ? i18n.t(key, options) : null
}

export default i18n
