import { createPath } from './path-utils'
import { PathGenerator, PathGeneratorWithId } from './path.types'

const USER_PATH = '/user'

export const userProfilePath: PathGenerator = () => {
  return createPath(USER_PATH, { suffix: 'profile' })
}

export const userSettingsPath: PathGenerator = () => {
  return createPath(USER_PATH, { suffix: 'settings' })
}

export const userListManagementPath: PathGenerator = () => {
  return createPath(USER_PATH, { suffix: 'list' })
}

export const userDetailsPath: PathGeneratorWithId = (id) => {
  return createPath(USER_PATH, { id, suffix: 'details' })
}

export const adminFormPath: PathGenerator = () => {
  return createPath(USER_PATH, { suffix: 'form-admin' })
}
