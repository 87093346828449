import React from 'react'
import { Button, ButtonProps, Spinner, SpinnerProps } from 'react-bootstrap'
import classnames from 'classnames'

type SubmitButtonProps = ButtonProps & {
  loading?: boolean
  loadingProps?: SpinnerProps
  loadingClassname?: string
}

export const SubmitButton = (props: SubmitButtonProps) => {
  const {
    loading,
    loadingProps,
    children,
    className,
    loadingClassname,
    disabled,
    variant = 'primary',
    ...buttonProps
  } = props

  return (
    <Button
      type='submit'
      variant={variant}
      className={classnames('d-flex align-items-center gap-0 m-0 fw-bold', className)}
      {...buttonProps}
      disabled={loading || disabled}
    >
      {loading && (
        <Spinner
          as='span'
          animation='grow'
          size='sm'
          role='status'
          aria-hidden='true'
          className={classnames('me-2', loadingClassname)}
          {...loadingProps}
        />
      )}
      {children}
    </Button>
  )
}
