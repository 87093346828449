import { UserType } from 'types/global.types'
import { MenuItemType, ADMIN_MENU_ITEMS, SUPER_ADMIN_MENU_ITEMS } from '../constant'

const getMenuItems = (userType: UserType) => {
  // NOTE - You can fetch from server and return here as well

  switch (userType) {
    case 'super-admin':
      return SUPER_ADMIN_MENU_ITEMS
    case 'admin':
      return ADMIN_MENU_ITEMS
    default:
      return ADMIN_MENU_ITEMS
  }
}

const findAllParent = (menuItems: MenuItemType[], menuItem: MenuItemType): string[] => {
  let parents: string[] = []
  const parent = findMenuItem(menuItems, menuItem.parentKey)

  if (parent) {
    parents.push(parent.key)
    if (parent.parentKey) parents = [...parents, ...findAllParent(menuItems, parent)]
  }

  return parents
}

const findMenuItem = (
  menuItems: MenuItemType[] | undefined,
  menuItemKey: MenuItemType['key'] | undefined
): MenuItemType | null => {
  if (menuItems && menuItemKey) {
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].key === menuItemKey) {
        return menuItems[i]
      }
      const found = findMenuItem(menuItems[i].children, menuItemKey)
      if (found) return found
    }
  }
  return null
}

export { getMenuItems, findAllParent, findMenuItem }
