/* eslint-disable prefer-const */
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
// import * as Sentry from '@sentry/browser'

import { App } from './app'

// let { NODE_ENV, SENTRY_DSN } = process.env
// if (NODE_ENV === 'production' && SENTRY_DSN) {
//   Sentry.init({
//     dsn: SENTRY_DSN,
//     tracesSampleRate: 0.5,
//   })
// }

const root = createRoot(document.getElementById('app'))
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
