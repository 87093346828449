import { Instance, SnapshotOut, applySnapshot, flow, types } from 'mobx-state-tree'

import { ApiSuccessResult, formDataApi, profileApi, userApi } from 'services/api'

import { PaymentAnalyticsModel, ProfileModel } from './profile'
import { withSetValue } from './helpers/with-set-value'

export const ProfileStoreModel = types
  .model('ProfileStore')
  .props({
    profile: types.optional(ProfileModel, {}),
    paymentAnalytics: types.optional(PaymentAnalyticsModel, {}),
    tabId: types.optional(types.number, 0),
    newEmail: types.maybeNull(types.string),
    dataPhoneNumber: types.maybeNull(
      types.array(
        types.model({
          id: types.maybe(types.number),
          phoneCode: types.maybeNull(types.string),
          countryName: types.maybeNull(types.string),
          countryShortName: types.maybeNull(types.string),
          countryIcon: types.maybeNull(types.string),
        })
      )
    ),

    generalInfo: types.maybeNull(
      types.model({
        id: types.maybe(types.number),
        informationType: types.maybeNull(types.number),
        title: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
      })
    ),
  })
  .extend(withSetValue)
  .actions((self) => ({
    getTab: function (id: number) {
      self.tabId = id
    },

    getPhoneNumberList: flow(function* () {
      const { ok, data }: ApiSuccessResult = yield profileApi.getPhoneNumber()

      if (ok) {
        applySnapshot(self, {
          ...self,
          dataPhoneNumber: data.data,
        })
      }

      return data
    }),

    getGeneralInformation: flow(function* (id: number) {
      const { ok, data }: ApiSuccessResult = yield profileApi.getGeneralInformation(id)

      if (ok) {
        applySnapshot(self, { ...self, generalInfo: data.data })
      }
    }),

    getProfile: flow(function* () {
      const result: ApiSuccessResult = yield profileApi.getProfile()

      if (result.ok) applySnapshot(self.profile, result.data.data)

      return result.ok
    }),

    deleteAccount: flow(function* () {
      const result: ApiSuccessResult = yield profileApi.deleteAccount()

      return result.ok
    }),

    deleteProfilePicture: flow(function* (mediaId) {
      const result: ApiSuccessResult = yield profileApi.deleteProfilePicture(mediaId)

      return result.ok
    }),

    changePassword: flow(function* (formData: Record<string, any>) {
      const result: ApiSuccessResult = yield profileApi.changePassword(formData)

      return result.ok
    }),

    updateProfile: flow(function* (formData: Record<string, any>, isSuperAdmin = false) {
      const result: ApiSuccessResult = isSuperAdmin
        ? yield formDataApi.updateAdminProfile(formData)
        : yield formDataApi.updateProfile(formData)

      return result.ok
    }),

    userPaymentAnalytics: flow(function* () {
      const result: ApiSuccessResult = yield profileApi.getPaymentAnalytics()

      if (result.ok) applySnapshot(self.paymentAnalytics, result.data.data)
    }),

    sendVerifyNewEmail: flow(function* (email: Record<string, any>) {
      const result: ApiSuccessResult = yield userApi.sendNewEmailVerify(email)

      return result.ok
    }),

    verifyNewEmail: flow(function* (formData: Record<string, any>) {
      const result: ApiSuccessResult = yield userApi.verifyNewEmail(formData)

      return result.ok
    }),
  }))

export type ProfileStore = Instance<typeof ProfileStoreModel>
export type ProfileStoreSnapshot = SnapshotOut<typeof ProfileStoreModel>
