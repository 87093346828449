import { Instance, SnapshotOut, types } from 'mobx-state-tree'
export const AppStateStoreModel = types
  .model('UserStore')
  .props({
    id: types.maybe(types.number),
    subdomain: types.maybe(types.string),
    sidebarMode: types.optional(types.enumeration(['condensed', 'fixed']), 'condensed'),
    colorScheme: types.optional(types.enumeration(['light', 'dark']), 'light'),
  })
  .views((self) => ({
    get darkModeEnabled() {
      return self.colorScheme === 'dark'
    },
  }))
  .actions((self) => ({
    setSubdomain(subdomain: string) {
      self.subdomain = subdomain
    },
    toggleSidebarMode(mode?: string) {
      if (mode) {
        self.sidebarMode = mode
        return
      }

      if (self.sidebarMode === 'condensed') {
        self.sidebarMode = 'fixed'
      } else {
        self.sidebarMode = 'condensed'
      }
    },
    toggleDarkMode() {
      if (self.colorScheme === 'dark') {
        self.colorScheme = 'light'
      } else {
        self.colorScheme = 'dark'
      }
    },
  }))

export type AppStateStore = Instance<typeof AppStateStoreModel>
export type AppStateStoreSnapshot = SnapshotOut<typeof AppStateStoreModel>
