import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useStores } from 'models'

export const BasicToast = observer(() => {
  const { t } = useTranslation()
  const {
    toastStore: {
      basic: { visible, displayTitle, body, variant, icon, hide },
    },
  } = useStores()

  return (
    <Modal show={visible} onHide={hide} size='sm'>
      <div className={classNames('modal-filled', 'bg-' + variant)}>
        <Modal.Body className='p-4'>
          <div className='text-center'>
            <i className={classNames('h1', icon)}></i>
            <h4 className='mt-2'>{displayTitle}</h4>
            <p className='mt-2'>{body}</p>
            <Button variant='outline-light' className='mt-3 mb-2' onClick={hide}>
              {t('Dismiss')}
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
})
