import { userRoutes, challengesRoutes } from 'pages/shared/routes'
import { superAdminRoutes } from 'pages/super-admin/routes'
import { RouteObject } from 'react-router-dom'

const SuperAdminRoutes: RouteObject[] = [
  ...userRoutes(),
  ...challengesRoutes(),
  ...superAdminRoutes(),
]

export default SuperAdminRoutes
