import React, { FunctionComponent } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Otptimer } from 'otp-timer-ts'

import { FormInput, VerticalForm } from 'components'

import { useStores } from 'models'
import useEmailVerify from './change-email-verify.hook'

const ChangeEmailVerif: FunctionComponent = observer(() => {
  const { t } = useTranslation()

  const {
    profileStore: { newEmail },
  } = useStores()

  const { doVerify, tokenResolver, error, backTo, doResendCode } = useEmailVerify()

  return (
    <>
      <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5} xxl={4}>
              <Card>
                <Card.Header className='py-1 text-center bg-black'></Card.Header>
                <Card.Body className='px-4 py-3'>
                  <div className='m-auto'>
                    <p className='tittle-auth mt-0 d-flex align-content-center'>
                      <i onClick={backTo} className='icon-arrow_to_left icons-auth ' />
                      {t('Verify your email')}
                    </p>
                    <p className='text-neutral-4 mb-2 fw-semibold'>
                      {t('We’ve sent a verification to')} <br /> {newEmail},
                      <span className='text-primary-green clickable' onClick={backTo}>
                        {t(' Change Email')}
                      </span>
                    </p>
                  </div>
                  <VerticalForm onSubmit={doVerify} resolver={tokenResolver}>
                    <FormInput
                      label={t('Verification Code')}
                      type='number'
                      name='token'
                      placeholder={t('Enter your code')}
                    />
                    {error && <p className='text-danger m-0'>{error}</p>}
                    <div className='otp-timer mt-1'>
                      <Otptimer
                        minutes={4}
                        seconds={59}
                        text={t('Resend code will available in :')}
                        buttonText={t('Resend Code')}
                        onResend={doResendCode}
                      />
                    </div>
                    <div className='text-center mt-3'>
                      <Button variant='primary' type='submit' className='rounded px-4 py-2 fw-600'>
                        {t('Verify')}
                      </Button>
                    </div>
                  </VerticalForm>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
})

export default ChangeEmailVerif
