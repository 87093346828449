import React, { FunctionComponent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { SubMenus } from './types'
import MenuItemLink from './menu-item-link'

const MenuItem: FunctionComponent<SubMenus> = (props) => {
  const { item, linkClassName } = props

  const { pathname } = useLocation()
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(pathname.startsWith(item.url))
  }, [pathname])

  return (
    <li className={classNames('side-nav-item', { 'menuitem-active': isActive })}>
      <MenuItemLink item={item} className={linkClassName} />
    </li>
  )
}

export default MenuItem
