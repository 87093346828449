import { createPath } from './path-utils'
import { PathGenerator, PathGeneratorWithId } from './path.types'

const CALENDAR_PATH = '/calendar'

export const calendarPath: PathGenerator = () => {
  return createPath(CALENDAR_PATH)
}

export const calendarDetailPath: PathGeneratorWithId = (id) => {
  return createPath(CALENDAR_PATH, id)
}
