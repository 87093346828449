import React from 'react'
import classNames from 'classnames'

export interface ProfileBoxProps {
  className?: string
  userName: string
  size?: string
}

export const ProfileImage = (props: ProfileBoxProps) => {
  const { className, userName, size } = props

  return (
    <div className={classNames('profile-box', className)}>
      <div className={classNames('profile-icon', `avatar-${size}`)}>
        <p>{userName?.charAt(0)}</p>
      </div>
    </div>
  )
}
