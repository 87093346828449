import { RouteObject } from 'react-router-dom'

import {
  calendarRoutes,
  paymentRoutes,
  organizerRoutes,
  tutorialsRoutes,
} from 'pages/creator/routes'
import { userRoutes, challengesRoutes } from 'pages/shared/routes'

const AdminRoutes: RouteObject[] = [
  ...userRoutes(),
  ...challengesRoutes(),
  ...calendarRoutes(),
  ...paymentRoutes(),
  ...organizerRoutes(),
  ...tutorialsRoutes(),
]

export default AdminRoutes
