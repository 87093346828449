import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class SuperAdminApi extends ApiCore {
  async getUserLists(payload: Payload = {}) {
    return await this.get({
      path: '/manages/users',
      payload,
    })
  }

  async addAdmin(payload: Payload = {}) {
    return await this.post({
      path: '/manages/admins',
      payload,
    })
  }

  async getUsersDetails(id: number | string, payload: Payload = {}) {
    return await this.get({
      path: `users/${id}`,
      payload,
    })
  }

  async deleteUsers(id: number | string, payload: Payload = {}) {
    return await this.delete({
      path: `manages/users/${id}`,
      payload,
    })
  }

  async getEventLists(payload: Payload = {}) {
    return await this.get({
      path: 'challenges/manages',
      payload,
    })
  }

  async changeStatusEventApproval(id: number | string, payload: Payload = {}) {
    return await this.patch({
      path: `challenges/manages/${id}`,
      payload,
    })
  }

  async downloadEventManagementList() {
    return await this.get({
      path: 'challenges/manages/download',
      config: {
        responseType: 'blob',
      },
    })
  }

  async getAdminReportOverview() {
    return await this.get({ path: '/reports/admin/overview' })
  }

  async getAdminReportList(payload: Payload = {}) {
    return await this.get({ path: '/reports/admin/events', payload })
  }
}

export const superAdminApi = new SuperAdminApi()
