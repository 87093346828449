{
  "name": "bounce-dashboard",
  "version": "1.0.0",
  "browserslist": "> 0.5%, last 4 major versions, not dead",
  "source": "src/index.html",
  "scripts": {
    "start": "parcel",
    "build": "parcel build",
    "docker:start": "docker-compose -f .docker/docker-compose.dev.yml -p bounce-dashboard up",
    "docker:exec": "docker-compose -f .docker/docker-compose.dev.yml -p bounce-dashboard exec app",
    "docker:package": "docker-compose -f .docker/docker-compose.dev.yml -p bounce-dashboard exec app yarn",
    "docker:build": "docker-compose -f .docker/docker-compose.prod.yml -p bounce-dashboard build",
    "docker:prod": "docker-compose -f .docker/docker-compose.prod.yml -p bounce-dashboard up",
    "test": "jest",
    "compile": "tsc -p . --pretty",
    "format": "prettier --write .",
    "lint": "eslint . --fix --ext .js,.ts,.tsx",
    "checks": "yarn run compile && yarn run format && yarn run lint"
  },
  "license": "ISC",
  "devDependencies": {
    "@babel/preset-env": "^7.20.2",
    "@babel/preset-react": "^7.18.6",
    "@evilmartians/lefthook": "^1.2.2",
    "@parcel/resolver-default": "^2.8.3",
    "@testing-library/react": "^13.4.0",
    "@types/draftjs-to-html": "^0.8.4",
    "@types/jest": "^29.2.3",
    "@types/node": "^18.11.9",
    "@types/react": "^18.0.21",
    "@types/react-datepicker": "^4.10.0",
    "@types/react-dom": "^18.0.6",
    "@types/react-draft-wysiwyg": "^1.13.8",
    "@types/react-router-dom": "^5.3.3",
    "@typescript-eslint/eslint-plugin": "^5.43.0",
    "@typescript-eslint/parser": "^5.43.0",
    "babel-jest": "^29.3.1",
    "babel-plugin-module-resolver": "^4.1.0",
    "buffer": "^6.0.3",
    "eslint": "^8.27.0",
    "eslint-config-prettier": "^8.5.0",
    "eslint-config-standard": "^17.0.0",
    "eslint-plugin-import": "^2.26.0",
    "eslint-plugin-jest": "^27.1.5",
    "eslint-plugin-n": "^15.5.1",
    "eslint-plugin-promise": "^6.1.1",
    "eslint-plugin-react": "^7.31.10",
    "eslint-plugin-react-hooks": "^4.6.0",
    "eslint-plugin-standard": "^5.0.0",
    "jest": "^29.3.1",
    "jest-environment-jsdom": "^29.3.1",
    "prettier": "2.7.1",
    "process": "^0.11.10",
    "tsc-files": "^1.1.3",
    "typescript": "^4.8.4"
  },
  "dependencies": {
    "@babel/preset-typescript": "^7.18.6",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/react": "^6.1.15",
    "@hookform/resolvers": "^2.8.8",
    "@parcel/packager-raw-url": "2.7.0",
    "@parcel/transformer-sass": "2.7.0",
    "@parcel/transformer-webmanifest": "2.7.0",
    "@sentry/browser": "^7.85.0",
    "@splidejs/react-splide": "^0.7.12",
    "@tanstack/react-table": "^8.7.9",
    "@tippy.js/react": "^3.1.1",
    "@types/google.maps": "^3.58.1",
    "@types/html-to-draftjs": "^1.4.3",
    "apexcharts": "3.33.1",
    "apisauce": "^2.1.6",
    "axios": "^1.2.0",
    "bootstrap": "5.1.3",
    "coloquent": "^3.0.1-beta",
    "country-flag-emoji-polyfill": "^0.1.8",
    "draft-js": "^0.11.7",
    "draftjs-to-html": "^0.9.1",
    "html-to-draftjs": "^1.5.0",
    "i18next": "^21.6.14",
    "i18next-browser-languagedetector": "^6.1.3",
    "i18next-icu": "^2.1.0",
    "intl-messageformat": "^10.3.0",
    "lodash": "^4.17.21",
    "lottie-web": "^5.12.2",
    "mobx": "6.6.0",
    "mobx-react-lite": "3.4.0",
    "mobx-state-tree": "5.1.5",
    "moment": "^2.29.4",
    "otp-timer-ts": "^2.1.5",
    "parcel": "^2.7.0",
    "parcel-resolver-path-alias": "^1.0.1",
    "react": "^18.2.0",
    "react-apexcharts": "1.3.9",
    "react-bootstrap": "^2.7.0",
    "react-datepicker": "^4.11.0",
    "react-datetime-picker": "^5.5.1",
    "react-dom": "^18.2.0",
    "react-draft-wysiwyg": "^1.15.0",
    "react-dropzone": "12.0.4",
    "react-error-boundary": "^3.1.4",
    "react-hook-form": "7.27.1",
    "react-i18next": "^11.15.6",
    "react-id-generator": "^3.0.2",
    "react-qr-code": "^2.0.11",
    "react-router-dom": "^6.4.2",
    "react-select": "5.2.2",
    "react-simple-wysiwyg": "^3.1.3",
    "reactotron-mst": "^3.1.4",
    "reactotron-react-js": "^3.3.8",
    "simplebar-react": "2.3.6",
    "suspend-react": "^0.0.9",
    "yup": "^0.32.11"
  }
}
