import Icon from 'components/icon'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CategoryComponentProps {
  category: string
}

export const CategoryChallenge = ({ category }: CategoryComponentProps) => {
  const { t } = useTranslation()

  switch (category) {
    case 'adventure':
      return (
        <p className='level-challenges'>
          <Icon icon='icon-adventure' size={16} />
          <span className='text-capitalize'>{t('Adventure')}</span>
        </p>
      )
    case 'health':
      return (
        <p className='level-challenges'>
          <Icon icon='icon-heart-rate' size={16} />
          <span className='text-capitalize'>{t('Health')}</span>
        </p>
      )
    case 'sport':
      return (
        <p className='level-challenges'>
          <Icon icon='icon-streght' size={16} />
          <span className='text-capitalize'>{t('Sport')}</span>
        </p>
      )
    case 'wellness':
      return (
        <p className='level-challenges'>
          <Icon icon='icon-meditation' size={16} />
          <span className='text-capitalize'>{t('Wellness')}</span>
        </p>
      )
    default:
      return (
        <p className='level-challenges'>
          <Icon icon='icon-other-sport' size={16} />
          <span className='text-capitalize'>{t('Others')}</span>
        </p>
      )
  }
}
