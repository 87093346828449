import React, { useCallback, useState } from 'react'
import { Form, FormControlProps, InputGroup } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'
import classNames from 'classnames'

type InputProps = FormControlProps & {
  label?: string
  name: string
  containerClass?: string
  step?: number
  min?: number
  max?: number
  required?: boolean
}

export const ControlledPasswordInput = (props: InputProps) => {
  const { label, name, containerClass, step, required, ...otherProps } = props

  const { control } = useFormContext()

  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, error },
  } = useController({
    control,
    name,
  })

  const [showPassword, setShowPassword] = useState(false)

  const togglePassword = useCallback(() => {
    setShowPassword((prev) => !prev)
  }, [])

  return (
    <Form.Group className={containerClass}>
      <div className='d-flex gap-1'>
        {label && <Form.Label htmlFor={name}>{label}</Form.Label>}
        {required && <span className='text-primary'>*</span>}
      </div>

      <InputGroup>
        <Form.Control
          name={name}
          id={name}
          type={showPassword ? 'text' : 'password'}
          isInvalid={invalid}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          step={step}
          {...otherProps}
          autoComplete={name}
        />

        <InputGroup.Text
          className={classNames('input-group-password', { 'show-password': showPassword })}
          data-password={showPassword ? 'true' : 'false'}
          onClick={togglePassword}
        >
          <span className='password-eye'></span>
        </InputGroup.Text>
      </InputGroup>

      {error && <Form.Text className='text-danger mt-1'>{error.message}</Form.Text>}
    </Form.Group>
  )
}
