import { Payload } from 'services/api'

import { SelectedChallengeType } from 'models/selected-challenge'
import { TicketType, UploadingImageType } from 'models/challenge'

export const buildChallengePayload = (payload: Payload & SelectedChallengeType) => {
  const {
    title,
    label,
    category,
    startDate,
    endDate,
    location,
    longitude,
    latitude,
    timezone,
    overview,
    benefits,
    media,
    videoLink,
    videoLinkID,
    tickets,
    tasks,
    safetyTipsAndGuidelines,
    additionalInformation,
    thumbnails,
    status,
  } = payload

  return {
    title,
    benefits,
    category,
    overview,
    participantLimit: tickets
      .map((ticket: TicketType) => ticket.max_quantity)
      .reduce((a: number, b: number) => a + b, 0),
    price: tickets[0].price,
    eventLabel: label,
    location,
    longitude,
    latitude,
    timezone,
    safetyGuide: safetyTipsAndGuidelines,
    videoLink,
    videoLinkID,
    startDate,
    endDate,
    additionalInformation,
    tasks: tasks.map((task) => ({
      ...task,
      category,
    })),
    tickets: tickets.map((ticket) => {
      const merchandises = ticket.merchandises as UploadingImageType[]

      return {
        ...ticket,
        itemIncude: ticket.includes,
        maxQuantity: ticket.max_quantity,
        merchandises: merchandises
          .filter((merchandise) => merchandise?.image?.base64Data)
          .map((merchandise) => ({
            fileName: merchandise.name,
            base64: merchandise?.image?.base64Data,
          })),
      }
    }),
    media: (media as UploadingImageType[])[0]?.image?.base64Data
      ? {
          fileName: (media as UploadingImageType[])[0]?.name,
          base64: (media as UploadingImageType[])[0]?.image?.base64Data,
        }
      : null,
    medias: (thumbnails as UploadingImageType[])
      .filter((thumbnail) => thumbnail?.image?.base64Data)
      .map((thumbnail) => ({
        fileName: thumbnail.name,
        base64: thumbnail.image.base64Data,
      })),
    status,
  }
}
