import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { useStores } from 'models'
import { useAuth } from 'models/hooks/use-auth'

export default function useRegisterVerification() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [error, setError] = useState(null)

  const { isLoading, handleVerifyEmail, handleResendVerifyEmail } = useAuth()

  const {
    authStore: { isAuthenticated, email },
  } = useStores()

  const tokenResolver = yupResolver(
    yup.object().shape({
      token: yup
        .number()
        .required(t('Please enter your code'))
        .typeError(t('Please enter your code')),
    })
  )

  const handleBack = () => {
    navigate('/account/register')
    sessionStorage.clear()
  }

  const navigateToDashboard = () => {
    navigate('/welcome-organizer', { replace: true })
  }

  const onVerifyEmail = async (data: any) => {
    setError(null)

    const ok = await handleVerifyEmail({ email, ...data })
    if (!ok) return setError(t('The code you entered is incorrect!'))
    navigateToDashboard()
  }

  const onResendVerifyEmail = async () => {
    setError(null)

    const ok = await handleResendVerifyEmail()
    if (!ok) return setError(t('You already requested, wait for 5 minutes for resend!'))
  }

  useEffect(() => {
    if (isAuthenticated) navigateToDashboard()
  }, [isAuthenticated])

  return { isLoading, error, onVerifyEmail, onResendVerifyEmail, handleBack, tokenResolver }
}
