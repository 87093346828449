import React, { FC, memo } from 'react'
import classNames from 'classnames'
import { TabMenuProps } from './index.types'
import AccordionToggle from 'components/accordion-toggle'
import Icon from 'components/icon'
import { useNavigate } from 'react-router-dom'
import { Accordion } from 'react-bootstrap'

const TabMenu: FC<TabMenuProps> = (props) => {
  const {
    index,
    itemId,
    isActive = false,
    label,
    completed = false,
    icon,
    handleClick,
    link,
    isParent,
  } = props

  const navigate = useNavigate()

  return (
    <>
      <Accordion.Item eventKey={`${props.index}`} className='custom-accordion-tutorial-item'>
        <Accordion.Header className='custom-accordion-tutorial-header'>
          <div
            className={classNames(
              'side-menu-item border rounded clickable mb-2 d-flex  justify-content-between align-items-center side-menu-hover w-100',
              { 'bg-primary-blue': isActive }
            )}
            onClick={() => {
              link ? navigate(link) : handleClick && handleClick(index, itemId)
            }}
          >
            <span
              className={classNames(
                'd-flex align-items-center gap-2',
                isActive ? 'text-white' : 'text-black'
              )}
            >
              {icon && <Icon icon={icon} />}
              <h5 className='m-0 lh-sm'>{label}</h5>
            </span>
            {completed && <i className='icon-check icon-input' />}
            {isParent && (
              <AccordionToggle
                eventKey={`${index}`}
                className={classNames('bg-transparent', isActive ? 'text-white' : 'text-black')}
              />
            )}
          </div>
        </Accordion.Header>
      </Accordion.Item>
    </>
  )
}

export default memo(TabMenu)
