import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class ProfileApi extends ApiCore {
  async getProfile() {
    return await this.get({
      path: '/users/me',
    })
  }

  async getOrganizerProfile() {
    return await this.get({
      path: '/auth/creator/profiles/organizer',
    })
  }

  async deleteAccount() {
    return await this.delete({
      path: '/users/delete',
    })
  }

  async deleteProfilePicture(mediaId: string) {
    return await this.delete({
      path: `challenges/medias/${mediaId}/0`,
    })
  }

  async changePassword(payload: Payload = {}) {
    return await this.patch({
      path: '/users/password',
      payload,
    })
  }

  async getPaymentAnalytics(payload: Payload = {}) {
    return await this.get({
      path: '/users/transactions/creator/analytics',
      payload,
    })
  }

  async getPhoneNumber(payload = {}) {
    return await this.get({ path: '/phone-codes', payload })
  }

  async getGeneralInformation(id: number | string) {
    return await this.get({ path: `/general-information/${id}` })
  }

  async getOrganizerReportOverview() {
    return await this.get({ path: '/reports/overview' })
  }

  async getOrganizerReportList(payload: Payload) {
    return await this.get({ path: '/reports/events', payload })
  }
}

export const profileApi = new ProfileApi()
