import { calendarPath } from 'config/paths/calendar-path'
import { MenuItemType } from './menu.types'
import { tutorialPath } from 'config/paths/tutorial-path'
import { paymentPath } from 'config/paths'

export const ADMIN_MENU_ITEMS: MenuItemType[] = [
  {
    key: 'navigation',
    label: 'Navigation',
    isTitle: true,
  },
  {
    key: 'challenge',
    label: 'Events',
    url: '/challenges',
    icon: 'icon-medal',
  },
  {
    key: 'calendar',
    label: 'Calendar',
    url: calendarPath(),
    icon: 'icon-calendar',
  },
  {
    key: 'payment',
    label: 'Payment',
    url: paymentPath(),
    icon: 'mdi mdi-wallet',
  },
  {
    key: 'organizer-report',
    label: 'Organizer Report',
    url: '/organizer-report',
    icon: 'icon-notebook',
  },
  {
    key: 'tutorials',
    label: 'Tutorials',
    url: tutorialPath(),
    icon: 'icon-rocket',
  },
]
