import { createPath } from './path-utils'
import { PathGenerator, PathGeneratorWithParams } from './path.types'

const ADMIN_PATH = '/admin'

export const adminReportingPath: PathGenerator = () => {
  return createPath(ADMIN_PATH, { suffix: 'reporting' })
}

export const adminFinancialPath: PathGeneratorWithParams = (id) => {
  return createPath(ADMIN_PATH, { id, suffix: 'financial' })
}
