import { useToast } from 'hooks/use-toast.hook'
import { capitalize } from 'lodash'
import { useStores } from 'models/helpers/use-stores'
import { ChangePasswordType } from 'pages/shared/settings/components/change-password/change-password.hook'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useProfile = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const {
    profileStore: {
      getProfile,
      updateProfile,
      deleteAccount,
      deleteProfilePicture,
      changePassword,
      getPhoneNumberList,
    },
  } = useStores()

  const { showToast } = useToast()

  const handleGetProfile = async () => {
    try {
      await getProfile()
    } catch (error) {
      showToast({
        variant: 'danger',
        body: capitalize(error?.data?.errors),
        title: t('Failed to get profile!'),
      })
    }
  }

  const handleUpdateProfile = async <T>(data: T, isSuperAdmin = false) => {
    try {
      setIsLoading(true)
      await updateProfile(data, isSuperAdmin)
      await handleGetProfile()

      showToast({
        variant: 'success',
        body: t('Update Profile Success'),
      })
    } catch (error) {
      showToast({
        variant: 'danger',
        body: capitalize(error?.data?.errors) || 'Something went wrong',
        title: t('Failed to edit profile!'),
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteProfile = async () => {
    try {
      setIsLoading(true)
      return await deleteAccount()
    } catch (error) {
      showToast({
        variant: 'danger',
        body: capitalize(error?.data?.errors),
        title: t('Failed to delete profile!'),
      })

      return false
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteProfilePicture = async (mediaId: string | number) => {
    try {
      setIsLoading(true)
      await deleteProfilePicture(mediaId)
      await handleGetProfile()
    } catch (error) {
      showToast({
        variant: 'danger',
        body: capitalize(error?.data?.errors),
        title: 'Failed to delete profile picture!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangePassword = async ({
    currentPassword,
    newPassword,
    confirmPassword,
  }: ChangePasswordType) => {
    try {
      setIsLoading(true)
      await changePassword({ currentPassword, newPassword, confirmPassword })
      showToast({
        variant: 'success',
        body: t('Changes password success'),
      })
    } catch (error) {
      showToast({
        variant: 'danger',
        body: capitalize(error?.data?.errors),
        title: t('Failed to change password!'),
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleGetPhoneNumber = async () => {
    try {
      return await getPhoneNumberList()
    } catch (error) {
      showToast({
        variant: 'danger',
        body: capitalize(error?.data?.errors),
        title: t('Failed to get phone number list!'),
      })
      return null
    }
  }

  return {
    isLoading,
    handleGetProfile,
    handleUpdateProfile,
    handleDeleteProfile,
    handleDeleteProfilePicture,
    handleChangePassword,
    handleGetPhoneNumber,
  }
}
