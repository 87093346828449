import React, { FunctionComponent, useRef, useState } from 'react'

import { MenuItemType } from '../constant'

import MenuItem from './menu-item'
import MenuItemWithChildren from './menu-item-with-children'
import { findAllParent } from '../helpers/helpers'
import { useStores } from 'models'

type AppMenuProps = {
  menuItems: Array<MenuItemType>
}

const Menu: FunctionComponent<AppMenuProps> = (props) => {
  const { menuItems } = props

  const {
    profileStore: {
      profile: { userType },
    },
  } = useStores()

  const menuRef = useRef<HTMLUListElement>(null)

  const [activeMenuItems, setActiveMenuItems] = useState<Array<string>>([])

  /*
   * toggle the menus
   */
  const toggleMenu = (menuItem: MenuItemType, show: boolean) => {
    if (show) setActiveMenuItems([menuItem.key, ...findAllParent(menuItems, menuItem)])
  }

  if (!userType) return null

  return (
    <ul className='side-nav' ref={menuRef} id='main-side-menu'>
      {(menuItems || []).map((item, index) => {
        return (
          <React.Fragment key={index.toString()}>
            {item.isTitle ? (
              <li className='side-nav-title side-nav-item'>{item.label}</li>
            ) : (
              <>
                {item.children ? (
                  <MenuItemWithChildren
                    item={item}
                    toggleMenu={toggleMenu}
                    subMenuClassNames='side-nav-second-level'
                    activeMenuItems={activeMenuItems}
                    linkClassName='side-nav-link'
                  />
                ) : (
                  <MenuItem item={item} linkClassName='side-nav-link' />
                )}
              </>
            )}
          </React.Fragment>
        )
      })}
    </ul>
  )
}

export default Menu
