import classNames from 'classnames'
import React from 'react'

import { ProfileImage } from 'components/profile-generator/profile-image'
import { defaultProfile } from 'assets/images'

type RoundedImageProps = {
  image?: string
  size?: string
  alt?: string
  avatarClassNames?: string
  fallback?: string
}

export const RoundedImage = (props: RoundedImageProps) => {
  const { image = '', size = 'sm', alt = '', avatarClassNames, fallback } = props

  if (!image && fallback) return <ProfileImage userName={fallback} size={size} />

  return (
    <img
      src={image || defaultProfile}
      className={classNames(
        'rounded-circle image-thumbnail',
        `${avatarClassNames}`,
        `avatar-${size}`,
        {
          'img-error': !image,
        }
      )}
      alt={alt}
      onError={(event) => {
        event.currentTarget.src = defaultProfile
        event.currentTarget.className += ' img-error'
      }}
    />
  )
}
