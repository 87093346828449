import { useStores } from 'models'
import { useProfile } from 'models/hooks/use-profile'
import { useEffect } from 'react'

export const PhoneNumberList = (): null => {
  const {
    profileStore: { setValue },
  } = useStores()

  const { handleGetPhoneNumber } = useProfile()

  const onGetPhoneNumber = async () => {
    const response = await handleGetPhoneNumber()

    setValue('dataPhoneNumber', response?.data)
  }

  useEffect(() => {
    onGetPhoneNumber()
  }, [])

  return null
}
