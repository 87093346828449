import React from 'react'
import { Form, FormCheckProps } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'

type RadioProps = FormCheckProps & {
  label?: string
  name: string
  containerClass?: string
}

export const ControlledRadio = (props: RadioProps) => {
  const { label, name, containerClass, ...otherProps } = props

  const { register, control } = useFormContext()
  const {
    fieldState: { invalid, error },
  } = useController({ control, name })

  return (
    <Form.Group className={containerClass}>
      <Form.Check
        id={`${name}-${otherProps.value}`}
        label={label}
        isInvalid={invalid}
        {...register(name)}
        {...otherProps}
        type='radio'
      />

      {error && (
        <Form.Control.Feedback type='invalid' className='d-block'>
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
