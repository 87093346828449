{
  "Already have an account": "Already have an account",
  "Back to home": "Back to home",
  "Change Email": "Change Email",
  "Confirm password": "Confirm your password",
  "Didn’t have an account ?": "Didn’t have an account ?",
  "Email": "Email",
  "Email has already registered": "Email has already registered",
  "Email is invalid!": "Email is invalid!",
  "Email or password is invalid!": "Email or password is invalid!",
  "Enter the email connected with your account and we’ll send anverification code to your email.": "Enter the email connected with your account and we’ll send a verification code to your email.",
  "Enter your code": "Verification Code",
  "Enter your email": "Enter your email",
  "Enter your email address and password to access the panel.": "Enter your email address and password to access the panel.",
  "Enter your information to create an account": "Enter your information to create an account",
  "Enter your name": "Enter your name",
  "Enter your password": "Enter your password",
  "Full name": "Full name",
  "Internal server error": "Internal server error",
  "Login": "Login",
  "Login here": "Login here",
  "Minimum 8 chars": "Minimum 8 chars",
  "New Password": "New Password",
  "Page not found": "Page not found",
  "Password": "Password",
  "Password does'nt match": "Password does'nt match",
  "Please enter a valid email": "Please enter a valid email",
  "Please enter the new password that is unique for this website": "Please enter the new password that is unique for this website",
  "Please enter your code": "Please enter your code",
  "Please enter your email": "Please enter your email",
  "Please enter your name": "Please enter your name",
  "Please enter your password": "Please enter your password",
  "Please enter your new password": "Please enter your new password",
  "Please re-enter your new password": "Please re-enter your new password",
  "Please enter your current password": "Please enter your current password",
  "Please filled all form": "Please filled all form",
  "Please re-enter your password": "Please re-enter your password",
  "Please try again later or you can contact our customers support": "Please try again later or you can contact our customers support",
  "Problem with connection": "Problem with connection",
  "Register": "Register",
  "Register Here": "Register Here",
  "Resend Code": "Resend Code",
  "Resend code will available in :": "Resend code will available in :",
  "Reset password": "Reset password",
  "Reset Password is successfully": "Reset Password is successfully",
  "Sign In": "Sign In",
  "The code you entered is incorrect!": "The code you entered is incorrect!",
  "Try Again": "Try Again",
  "Verification Code": "Verification Code",
  "Verify": "Verify",
  "Verify Email": "Verify Email",
  "Verify your email": "Verify your email",
  "We can’t find the page that you are looking for": "We can’t find the page that you are looking for",
  "We’ve sent a verification to": "We’ve sent a verification to",
  "Woops, there seems to be a problem with your network Connection.": "Woops, there seems to be a problem with your network Connection.",
  "You already requested, wait for 5 minutes for resend": "You already requested, wait for 5 minutes for resend",
  "welcome": "Welcome to Great!",
  "descriptionWelcome": "We can't wait to list your event on our platform! But before we get started, we need to know a little more about you."
}
