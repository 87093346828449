import React, { FunctionComponent, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { useAccountLayout } from 'hooks'
import { BasicToast } from './basic-toast'
import { StackedToast } from './stacked-toast'
import { PhoneNumberList } from 'handlers/phone-number-list-handler'

const AuthLayout: FunctionComponent = () => {
  useAccountLayout()

  return (
    <>
      <BasicToast />
      <StackedToast />
      <Suspense fallback={<div></div>}>
        <Outlet />
      </Suspense>
      <PhoneNumberList />
    </>
  )
}

export default AuthLayout
