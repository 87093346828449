import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import moment from 'moment'
import { CurrencyType, EventLabelType } from 'types/global.types'

export const UploadingImage = types.model('UploadingImage', {
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  image: types.maybeNull(
    types.model('UploadedImageFile', {
      preview: types.maybeNull(types.string),
      base64Data: types.optional(types.maybeNull(types.string), ''),
      name: types.optional(types.maybeNull(types.string), ''),
    })
  ),
})

export const UploadedImage = types.model('UploadedImage', {
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  cloudinaryID: types.optional(types.maybeNull(types.string), ''),
  s3Key: types.optional(types.maybeNull(types.string), ''),
  index: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

export const Merchandise = types.model('Merchandise', {
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  media_type: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deleted_at: types.maybeNull(types.string),
})

export const AddOn = types.model('AddOn', {
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  addOnsType: types.maybeNull(types.number),
  includes: types.maybeNull(types.array(types.maybeNull(types.string))),
  durationInSeconds: types.maybeNull(types.number),
  AIUsageCredit: types.maybeNull(types.number),
  media: UploadedImage,
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

export const Creator = types.model('Creator', {
  id: types.maybeNull(types.number),
  fullName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  userLevel: types.maybeNull(types.number),
  birthday: types.maybeNull(types.string),
  weight: types.maybeNull(types.number),
  height: types.maybeNull(types.number),
  age: types.maybeNull(types.number),
  gender: types.maybeNull(types.string),
  occupation: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  confirmedAt: types.maybeNull(types.string),
  isPreferenceAnswered: types.boolean,
  AIBalance: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  photoProfile: types.maybeNull(UploadedImage),
})

export const Ticket = types.model('Ticket', {
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  currency: types.maybe(types.enumeration<CurrencyType>(['usd', 'eur', 'idr'])),
  tooltip: types.optional(types.maybeNull(types.string), ''),
  overview: types.optional(types.maybeNull(types.string), ''),
  includes: types.maybeNull(types.array(types.maybeNull(types.string))),
  max_quantity: types.maybeNull(types.number),
  additionalInformation: types.optional(types.maybeNull(types.string), ''),
  merchandises: types.maybeNull(types.array(types.frozen())),
  createdAt: types.optional(types.maybeNull(types.string), ''),
  updatedAt: types.optional(types.maybeNull(types.string), ''),
  deleted_at: types.optional(types.maybeNull(types.string), ''),
})

const Challenger = types.model('Challenger', {
  id: types.maybeNull(types.number),
  progress: types.maybeNull(types.number),
  checkInAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deleted_at: types.maybeNull(types.string),
})

export const BaseChallenge = types.model('BaseChallenge', {
  id: types.maybeNull(types.number),
  category: types.maybeNull(
    // types.enumeration<EventCategoryType>(['adventure', 'health', 'sport', 'wellness'])
    types.string
  ),
  title: types.maybeNull(types.string),
  adminStatus: types.maybeNull(types.string),
  level: types.maybeNull(types.string),
  overview: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  benefits: types.maybeNull(types.array(types.maybeNull(types.string))),
  requirements: types.maybeNull(types.array(types.maybeNull(types.string))),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  maxMembers: types.maybeNull(types.number),
  additionalInformation: types.optional(types.maybeNull(types.string), ''),
  eventStatus: types.optional(types.maybeNull(types.string), ''),
  price: types.maybeNull(types.number),
  joinedMembers: types.maybeNull(types.number),
  taskCount: types.maybeNull(types.number),
  progress: types.maybeNull(types.number),
  safetyTipsAndGuidelines: types.optional(types.maybeNull(types.string), ''),
  termAndCondition: types.optional(types.maybeNull(types.string), ''),
  label: types.maybe(
    types.enumeration<EventLabelType>(['indoor event', 'outdoor event', 'virtual event'])
  ),
  location: types.maybeNull(types.string),
  latitude: types.maybeNull(types.number),
  timezone: types.maybeNull(types.string),
  longitude: types.maybeNull(types.number),
  distance: types.maybeNull(types.number),
  type: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  add_ons: types.maybeNull(types.string),
  creator: types.maybeNull(Creator),
  media: types.optional(types.frozen(), {}),
  thumbnails: types.optional(types.array(types.frozen()), []),
  tickets: types.maybeNull(types.array(types.maybeNull(Ticket))),
  merchandises: types.maybeNull(types.array(types.maybeNull(Merchandise))),
})

export const ChallengeModel = BaseChallenge.props({
  challenger: types.maybeNull(Challenger),
  add_ons: types.maybeNull(types.string),
}).views((self) => ({
  get startedDate() {
    return moment(self.startDate).format('Do MMM')
  },
  get endedDate() {
    return moment(self.endDate).format('Do MMM')
  },
  get status() {
    if (moment.utc().isBefore(moment.utc(self.startDate))) {
      return 'upcoming'
    } else if (moment.utc().isBetween(moment.utc(self.startDate), moment.utc(self.endDate))) {
      return 'ongoing'
    } else moment.utc().isAfter(moment.utc(self.endDate))
    return 'completed'
  },
}))

export type ChallengeInstance = Instance<typeof ChallengeModel>
export type Challenge = Omit<ChallengeInstance, symbol>
export type ChallengeSnapshot = SnapshotOut<typeof ChallengeModel>

export type TicketModelInstance = Instance<typeof Ticket>
export type TicketType = Omit<TicketModelInstance, symbol>
export type TicketModelSnapshot = SnapshotOut<typeof Ticket>

export type MediaModelInstance = Instance<typeof UploadedImage>
export type MediaType = Omit<MediaModelInstance, symbol>
export type MediaModelSnapshot = SnapshotOut<typeof UploadedImage>

export type UploadingImageModelInstance = Instance<typeof UploadingImage>
export type UploadingImageType = Omit<UploadingImageModelInstance, symbol>
export type UploadingImageModelSnapshot = SnapshotOut<typeof UploadingImage>

export const ChallengeAnalyticsModel = types.model('ChallengeAnalytics').props({
  all: types.maybe(
    types.model({
      created: types.maybe(types.maybeNull(types.number)),
      joined: types.maybe(types.maybeNull(types.number)),
    })
  ),
  active: types.maybe(
    types.model({
      created: types.maybe(types.maybeNull(types.number)),
      joined: types.maybe(types.maybeNull(types.number)),
    })
  ),
  completed: types.maybe(
    types.model({
      created: types.maybe(types.maybeNull(types.number)),
      joined: types.maybe(types.maybeNull(types.number)),
    })
  ),
})

export type ChallengeAnalyticsInstance = Instance<typeof ChallengeAnalyticsModel>
export type ChallengeAnalytics = Omit<ChallengeAnalyticsInstance, symbol>
export type ChallengeAnalyticsSnapshot = SnapshotOut<typeof ChallengeAnalyticsModel>

export const ChallengeManagementListModel = types.model('ChallengeManagementList').props({
  id: types.maybe(types.number),
  title: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  start_date: types.maybeNull(types.string),
  end_date: types.maybeNull(types.string),
  admin_status: types.maybeNull(types.string),
  ticket_quantity: types.maybeNull(types.number),
  joined_users: types.maybeNull(types.number),
  organizer_name: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  checked_by: types.maybeNull(types.string),
})

export type ChallengeManagementListInstance = Instance<typeof ChallengeManagementListModel>
export type ChallengeManagementList = Omit<ChallengeManagementListInstance, symbol>
export type ChallengeManagementListSnapshot = SnapshotOut<typeof ChallengeManagementListModel>
