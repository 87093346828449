import React, { useEffect } from 'react'
import lottie from 'lottie-web'

import { loader } from 'assets/images'

type LoaderProps = {
  preset?: 'centered' | 'cover-page'
}

export const Loader = ({ preset = 'centered' }: LoaderProps) => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#loader-animation'),
      animationData: loader,
      renderer: 'svg',
      loop: true,
      autoplay: true,
    })
  }, [])

  return (
    <div className={`preloader ${preset}`}>
      <div id='loader-animation' />
    </div>
  )
}
