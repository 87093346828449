import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { TableEmptyProps, TableErrorProps } from './table.types'
import { emptyState } from 'assets/images'
import Image from 'components/image'

export const TableError = ({ resetErrorBoundary, table }: TableErrorProps) => {
  const { t } = useTranslation()

  return (
    <tbody className='error bg-red'>
      <tr>
        <td colSpan={table.getAllColumns().length}>
          <div className='text-center pt-2 pb-3'>
            <div className='d-flex align-items-center justify-content-center text-danger mb-2'>
              <i className='mdi mdi-alert-rhombus mdi-24px me-1'></i>
              <h4 className='m-0'>{t('Unexpected Error')}</h4>
              <i className='mdi mdi-alert-rhombus mdi-24px ms-1'></i>
            </div>

            <p className='text-muted m-0'>{t('An error occured while fetching data.')}</p>
            <p className='text-muted mb-2'>{t('Please try again later.')}</p>

            <Button onClick={resetErrorBoundary} variant='primary'>
              <i className='mdi mdi-refresh me-1 text-danger'></i>
              {t('Retry')}
            </Button>
          </div>
        </td>
      </tr>
    </tbody>
  )
}

export const TableEmpty = ({ table }: TableEmptyProps) => {
  const { t } = useTranslation()

  return (
    <tbody className='table-empty'>
      <tr>
        <td colSpan={table.getAllColumns().length}>
          <div className='d-flex align-items-center justify-content-center flex-column gap-2 py-3'>
            <div>
              <Image image={emptyState} width={170} />
            </div>
            <div className='text-center text-black'>
              <h4 className='m-0'>{t('No Data We Found')}</h4>
            </div>
            <p className='text-center text-muted'>
              {t('Create your task and share your event to the world.')}
            </p>
          </div>
        </td>
      </tr>
    </tbody>
  )
}
