import { observer } from 'mobx-react-lite'
import React from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'

import { logoToast } from 'assets/images'
import { useStores } from 'models'

export const StackedToast = observer(() => {
  const {
    toastStore: { stacked },
  } = useStores()

  return (
    <ToastContainer position='top-end' className='p-3'>
      {stacked.slice().map((item, index) => (
        <Toast
          key={index}
          autohide
          delay={2000}
          show={item.visible}
          bg={item.variant}
          onClose={item.hide}
        >
          <Toast.Header closeVariant='white'>
            <img src={logoToast} className='rounded me-2' height='20' alt='' />
            <strong className='me-auto'>{item.displayTitle}</strong>
          </Toast.Header>
          <Toast.Body>{item.body}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  )
})
