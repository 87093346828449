import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'
import ReactAsyncSelect from 'react-select/async'
import classNames from 'classnames'

import { asyncSelectApi } from 'services/api'

type AsyncSelectProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  label?: string
  returnOnlyValue?: boolean
  apiPath: string
  apiQuery?: Record<string, any>
  dataKey?: string
  labelKey?: string
  containerClass?: string
  placeholder?: string
}

export const ControlledAsyncSelect = (props: AsyncSelectProps) => {
  const {
    name,
    label,
    apiPath,
    apiQuery,
    dataKey = 'id',
    labelKey = 'name',
    containerClass,
    placeholder,
    returnOnlyValue = true,
    onChange,
  } = props

  const selectRef = useRef<any>()

  const [isLoading, setIsLoading] = useState(false)

  const { control } = useFormContext()
  const {
    field: { onChange: onFormChange, value },
    fieldState: { error, invalid },
  } = useController({ control, name })

  const loadOptions = async (inputValue: string) => {
    try {
      setIsLoading(true)

      const query = { ...apiQuery }

      if (inputValue) query.search = inputValue

      const { ok, data } = await asyncSelectApi.getOptions(apiPath, query)

      if (ok) {
        const resolvedData = data.data.map((item: Record<string, any>) => ({
          label: item[labelKey],
          value: item[dataKey],
        }))

        return new Promise((resolve) => resolve(resolvedData))
      }
    } catch {
      return new Promise((resolve) => resolve([]))
    } finally {
      setIsLoading(false)
    }
  }

  const loadSelectedOption = async (id: string) => {
    const { ok, data } = await asyncSelectApi.getOptions(`${apiPath}/${id}`)

    if (ok) {
      selectRef.current?.setValue({ label: data[labelKey], value: data[dataKey] })
    }
  }

  const generateValue = async (value: any) => {
    if (returnOnlyValue) return loadSelectedOption(value)

    if (value?.[labelKey]) {
      selectRef.current?.setValue({ label: value[labelKey], value: value[dataKey] })
      return
    }

    if (value) selectRef.current?.setValue(value)
  }

  useEffect(() => {
    if (!value) return selectRef.current?.clearValue()

    generateValue(value)
  }, [selectRef, value])

  return (
    <Form.Group className={containerClass}>
      <Form.Label>{label}</Form.Label>
      <ReactAsyncSelect
        name={name}
        placeholder={placeholder}
        loadOptions={loadOptions}
        cacheOptions
        defaultOptions
        isClearable
        isLoading={isLoading}
        className={classNames('react-select', {
          'is-invalid': invalid,
        })}
        onChange={(event: any) => {
          onChange && onChange(event)
          onFormChange(returnOnlyValue ? event?.value : event)
        }}
        ref={selectRef}
      />

      {error && (
        <Form.Control.Feedback type='invalid' className='d-block'>
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
