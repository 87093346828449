import { createPath } from './path-utils'
import { PathGeneratorWithId, PathGeneratorWithParams } from './path.types'

const CONTENT_MANAGEMENT_PATH = '/content-management'

export const contentManagementPath: PathGeneratorWithId = (id) => {
  return createPath(CONTENT_MANAGEMENT_PATH, id)
}

export const contentManagementEditPath: PathGeneratorWithParams = (id) => {
  return createPath(CONTENT_MANAGEMENT_PATH, {
    id,
    suffix: 'edit',
  })
}
