import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class FormDataApi extends ApiCore {
  protected multipart = true

  async updateProfile(payload: Payload = {}) {
    return await this.put({
      path: '/auth/creator/profiles/organizer',
      payload,
    })
  }

  async updateAdminProfile(payload: Payload = {}) {
    return await this.put({
      path: '/auth/creator/profiles/admin',
      payload,
    })
  }

  async addPersonalInformation(payload: Payload = {}) {
    return await this.post({
      path: '/auth/creator/personal-info',
      payload,
    })
  }

  async updateAdminPayoutStatus(transactionId: number, payload: Payload = {}) {
    return await this.patch({
      path: `/payouts/admin/${transactionId}`,
      payload,
    })
  }
}

export const formDataApi = new FormDataApi()
