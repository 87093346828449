import { useState } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { useStores } from 'models'
import { ApiErrorKind } from 'services/api'

export default function useVerifyToken() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    authStore: { verifyTokenResetPass, resendCodeResetPassword, email, token, setValue },
  } = useStores()

  const [error, setError] = useState(null)
  const [errorSend, setErrorSend] = useState(null)

  const tokenResolver = yupResolver(
    yup.object().shape({
      code: yup
        .number()
        .required(t('Please enter your code'))
        .typeError(t('Please enter your code')),
    })
  )

  const navigateToResetPassword = () => {
    navigate('/account/reset-password', { replace: true })
  }

  const backTo = async () => {
    navigate('/account/register')
    sessionStorage.clear()
  }

  const doVerify = async (data: any) => {
    try {
      setValue('code', data.code)

      const submit = await verifyTokenResetPass({ email, token, ...data })

      if (submit) {
        navigateToResetPassword()
      }
    } catch (error: any) {
      if (
        error.kind === ApiErrorKind.UNPROCESSABLE ||
        error.kind === ApiErrorKind.NOT_FOUND ||
        error.kind === ApiErrorKind.BAD_REQUEST
      ) {
        setError(t('The code you entered is incorrect!'))
      }
    }
  }

  const doResendCode = async () => {
    try {
      await resendCodeResetPassword({ email })
    } catch (error: any) {
      if (
        error.kind === ApiErrorKind.UNPROCESSABLE ||
        error.kind === ApiErrorKind.NOT_FOUND ||
        error.kind === ApiErrorKind.BAD_REQUEST
      ) {
        setErrorSend(t('You already requested, wait for 5 minutes for resend'))
      }
    }
  }
  return { tokenResolver, doVerify, error, backTo, doResendCode, errorSend }
}
