import { Instance, SnapshotOut, applySnapshot, flow, types } from 'mobx-state-tree'

import { ApiSuccessResult, Payload, formDataApi } from 'services/api'

import { withReset } from './helpers/with-reset'
import {
  AdminRefundsPaymentsModel,
  PaymentForOrganizerModel,
  PaymentDetailOrganizerModel,
} from './payment'
import { paymentApi } from 'services/api/payment-api'

export const PaymentStoreModel = types
  .model('PaymentStore')
  .props({
    organizerPayments: types.array(PaymentForOrganizerModel),
    organizerPayment: types.optional(PaymentDetailOrganizerModel, {}),
    organizerRefunds: types.optional(types.array(AdminRefundsPaymentsModel), []),
    adminPayouts: types.array(PaymentForOrganizerModel),
    adminPayout: types.optional(PaymentDetailOrganizerModel, {}),
    adminRefunds: types.array(AdminRefundsPaymentsModel),
    adminRefund: types.optional(AdminRefundsPaymentsModel, {}),
    totalPageMonth: types.optional(types.number, 1),
    tabId: types.optional(types.number, 0),
    totalPage: types.optional(types.number, 1),
  })
  .actions(withReset)
  .actions((self) => ({
    getTab: function (id: number) {
      self.tabId = id
    },

    getOrganizerPayments: flow(function* (payload) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.sorts) {
        payload.direction = payload.sorts[Object.keys(payload.sorts)[0]]
        payload.sort = Object.keys(payload.sorts)[0]
        delete payload.sorts
      }

      const result: ApiSuccessResult = yield paymentApi.getOrganizerPayments(payload)

      if (result.ok) {
        applySnapshot(self.organizerPayments, result.data.data.datas)
        self.totalPage = result.data.data.totalPage
      }

      return result.ok
    }),

    getOrganizerPayment: flow(function* (challengeId: string) {
      const result: ApiSuccessResult = yield paymentApi.getOrganizerPayment(challengeId)

      if (result.ok) {
        applySnapshot(self.organizerPayment, {})
        applySnapshot(self.organizerPayment, result.data.data)
      }

      return result.ok
    }),

    getOrganizerRefunds: flow(function* (challengeId, payload) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.sorts) {
        payload.direction = payload.sorts[Object.keys(payload.sorts)[0]]
        payload.sort = Object.keys(payload.sorts)[0]
        delete payload.sorts
      }

      const result: ApiSuccessResult = yield paymentApi.getOrganizerRefunds(challengeId, payload)

      if (result.ok) {
        applySnapshot(self.organizerRefunds, result.data.data.datas)
        self.totalPage = result.data.data.totalPage
      }

      return result.ok
    }),

    getAdminPayouts: flow(function* (payload) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.sorts) {
        payload.direction = payload.sorts[Object.keys(payload.sorts)[0]]
        payload.sort = Object.keys(payload.sorts)[0]
        delete payload.sorts
      }

      const result: ApiSuccessResult = yield paymentApi.getAdminPayouts(payload)

      if (result.ok) {
        applySnapshot(self.organizerRefunds, [])
        applySnapshot(self.adminPayouts, result.data.data.datas)
        self.totalPage = result.data.data.totalPages
      }

      return result.ok
    }),

    getDetailAdminPayout: flow(function* (challengeId: string, userId: string) {
      const result: ApiSuccessResult = yield paymentApi.getDetailAdminPayout(challengeId, userId)

      if (result.ok) {
        applySnapshot(self.adminPayout, {})
        applySnapshot(self.adminPayout, result.data.data)
      }

      return result.ok
    }),

    getAdminRefunds: flow(function* (payload) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.sorts) {
        payload.direction = payload.sorts[Object.keys(payload.sorts)[0]]
        delete payload.sorts
      }

      const result: ApiSuccessResult = yield paymentApi.getAdminRefunds(payload)

      if (result.ok) {
        applySnapshot(self.adminRefunds, result.data.data.datas)
        self.totalPage = result.data.data.totalPages
      }

      return result.ok
    }),

    getDetailAdminRefunds: flow(function* (challengeId: string, userId: string) {
      const result: ApiSuccessResult = yield paymentApi.getDetailAdminRefunds(challengeId, userId)

      if (result.ok) {
        applySnapshot(self.adminRefund, {})
        applySnapshot(self.adminRefund, result.data.data)
      }

      return result.ok
    }),

    updateAdminRefundStatus: flow(function* (transactionId: number, payload: Payload) {
      const result: ApiSuccessResult = yield paymentApi.updateAdminRefundStatus(
        transactionId,
        payload
      )

      return result.ok
    }),

    updateAdminPayoutStatus: flow(function* (transactionId: number, payload: Payload) {
      const result: ApiSuccessResult = yield formDataApi.updateAdminPayoutStatus(
        transactionId,
        payload
      )

      return result.ok
    }),
  }))

export type PaymentStore = Instance<typeof PaymentStoreModel>
export type PaymentStoreSnapshot = SnapshotOut<typeof PaymentStoreModel>
