import { useEffect } from 'react'

export default function useAccountLayout() {
  useEffect(() => {
    if (!document.body) return null

    document.body.classList.add('authentication-bg')

    return () => document.body.classList.remove('authentication-bg')
  }, [])
}
