import Icon from 'components/icon'
import React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

type BackButtonProps = ButtonProps & {
  containerClass?: string
  icon?: boolean
  title?: string
  onClick?: () => void
  variant?: string
  size?: string
}

export const BackButton = (props: BackButtonProps) => {
  const {
    containerClass = 'border size-lg fw-bold',
    icon,
    title,
    variant = 'transparent',
    size,
    onClick,
  } = props

  return (
    <Button variant={variant} size={size} className={containerClass} onClick={onClick}>
      <div className='d-flex align-items-center justify-content-center gap-1 fw-bold text-black'>
        {icon && <Icon icon='icon-chevron-left' size={24} />}
        <span>{title}</span>
      </div>
    </Button>
  )
}
