import { ApiCore } from './api-core'

export class AsyncSelectApi extends ApiCore {
  async getOptions(path: string, payload = {}) {
    return await this.get({
      path,
      payload,
    })
  }
}

export const asyncSelectApi = new AsyncSelectApi()
