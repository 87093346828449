import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const EventsCalenderModel = types.model('EventsCalender').props({
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  start: types.maybeNull(types.string),
  end: types.maybeNull(types.string),
})

export type EventsCalenderInstance = Instance<typeof EventsCalenderModel>
export type EventsCalender = Omit<EventsCalenderInstance, symbol>
export type EventsCalenderSnapshot = SnapshotOut<typeof EventsCalenderModel>
