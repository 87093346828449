import React from 'react'
import { Navigate, Outlet, useMatch } from 'react-router-dom'

interface RedirectRouteProps {
  route: string
  redirectTo: string
}

const RedirectRoute = ({ route, redirectTo }: RedirectRouteProps) => {
  const match = useMatch(route)

  if (match) return <Navigate to={redirectTo} replace />

  return <Outlet />
}

export default RedirectRoute
