import { useStores } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function useLogout() {
  const navigate = useNavigate()
  const [loggedOut, setLoggedOut] = useState(false)

  const navigateToLogin = () => {
    navigate('/account/login', { replace: true })
  }
  const {
    authStore: { isAuthenticated },
    reset,
  } = useStores()

  const doLogout = async () => {
    sessionStorage.clear()
    reset()
    setLoggedOut(true)
    navigateToLogin()
  }

  useEffect(() => {
    if (isAuthenticated) {
      doLogout()
      return
    }

    if (!loggedOut) navigateToLogin()
  }, [loggedOut, isAuthenticated])

  return { loggedOut }
}
