import React from 'react'
import { Navigate } from 'react-router-dom'

import PageNotFound from 'pages/shared/errors/not-found-page'
import InternalServerError from 'pages/shared/errors/internal-server-error-page'
import NoInternetConnection from 'pages/shared/errors/no-internet-connection-page'

const ErrorRoutes = [
  { path: '/404', element: <PageNotFound /> },
  { path: '/500', element: <InternalServerError /> },
  { path: '/no-internet-connection', element: <NoInternetConnection /> },
  { path: '*', element: <Navigate to='/404' replace /> },
]

export default ErrorRoutes
