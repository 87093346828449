import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { SubmitButton } from 'components/forms'
import { Variant } from 'react-bootstrap/esm/types'

type ConfirmationModalProps = {
  title: string
  body: string
  visible?: boolean
  onCancel: () => void
  onConfirm: (() => void) | ((event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>)
  cancelLabel?: string
  confirmLabel?: string
  variant?: Variant
  isLoading?: boolean
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation()

  const {
    title,
    body,
    visible,
    onCancel,
    onConfirm,
    cancelLabel = t('Cancel'),
    confirmLabel = t('Yes, continue'),
    variant = 'success',
    isLoading,
  } = props

  return (
    <Modal show={visible} onHide={onCancel} centered backdrop='static'>
      <Modal.Header onHide={onCancel} className={classNames('text-white', `bg-${variant}`)}>
        <h4 className='fw-600 modal-title mt-0'>
          <i className='uil-exclamation-circle me-1' />
          {title}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <h5 className='fw-600 lh-sm'>{body}</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={onCancel} className='text-black fw-bold' size='lg'>
          {cancelLabel}
        </Button>
        <SubmitButton
          variant={variant}
          onClick={onConfirm}
          loading={isLoading}
          disabled={isLoading}
          size='lg'
        >
          {confirmLabel}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  )
}
