/* eslint-disable @typescript-eslint/ban-types */
import { ApiResponse, ApisauceInstance, create } from 'apisauce'
import { AxiosRequestConfig } from 'axios'
import get from 'lodash/get'
import omit from 'lodash/omit'
import snakeCase from 'lodash/snakeCase'

import {
  ACCESS_TOKEN_STORAGE_KEY,
  API_BASE_URL,
  API_TIMEOUT,
  LANGUAGE_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from 'config/env'
import { convertKeyToSnakeCase } from 'utils/object'
import { loadString, saveString } from 'utils/session-storage'

import { ApiParams, Payload, RequestMethod } from './api-core.types'
import { getGeneralApiProblem } from './api-problem'
import { serialize } from './serialize-formdata'
import { split } from 'lodash'

/**
 * Manages all requests to the API.
 */
export class ApiCore {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  private apisauce: ApisauceInstance

  /**
   * User type path prefix for user authantication.
   */
  protected userType = 'admin'

  /**
   * Use multipart/form-data content type.
   * For file uploading
   */
  protected multipart = false

  /**
   * Convert request object key to snake case.
   */
  protected useSnakedKey = false

  /**
   * Creates the api.
   *
   */
  constructor() {
    this.setUserType()

    this.apisauce = create({
      baseURL: API_BASE_URL,
      timeout: API_TIMEOUT,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    this.apisauce.addAsyncResponseTransform(
      async (response) => await this.unauthorizedTransformer(response)
    )
  }

  private getUserType() {
    const { hostname } = window.location
    const [subdomain] = hostname.split('.')

    switch (subdomain) {
      case 'vendor':
      case 'partner':
        return subdomain
      default:
        return 'admin'
    }
  }

  private setUserType() {
    this.userType = this.getUserType()
  }

  private async getNewAccessToken() {
    const refreshToken = await loadString(REFRESH_TOKEN_STORAGE_KEY)
    const authorizationBearer = await loadString(ACCESS_TOKEN_STORAGE_KEY)

    try {
      const response = await this.apisauce.get(
        '/auth/refresh-token',
        {},
        {
          headers: {
            'refresh-token': refreshToken,
            authorization: authorizationBearer,
            'x-retry': true,
          },
        }
      )

      if (response.ok) {
        await this.saveJWToken(response)
        return {
          authorization: response.headers?.authorization,
          client: response.headers?.client,
          refreshToken: get(response.headers, 'refresh-token', null),
          refreshTokenExpired: get(response.headers, 'refresh-token-expired', null),
        }
      }
      location.replace('/account/login')
      sessionStorage.clear()
      return null
    } catch (error) {
      return null
    }
  }

  private async unauthorizedTransformer(res: any) {
    if (res.status === 401 && !res.config.headers['x-retry']) {
      const token = await this.getNewAccessToken()

      if (!token) return

      const method: RequestMethod = res.config.method
      const secondTry = await this.apisauce[method](
        res.config.url,
        {},
        {
          ...res.config,
          headers: {
            ...res.config.headers,
            'x-retry': true,
            authorization: token.authorization,
          },
        }
      )

      res.duration = secondTry.duration
      res.problem = secondTry.problem
      res.originalError = secondTry.originalError
      res.ok = secondTry.ok
      res.status = secondTry.status
      res.headers = secondTry.headers
      res.config = secondTry.config
      res.data = secondTry.data
      res.headers = { ...secondTry.headers, 'refresh-token-expired': token.refreshTokenExpired }
    }
  }

  private processPayload(payloadWrapper?: string, payload: Payload = {}) {
    const filteredPayload = omit(payload, ['filters', 'sorts'])
    const serializedPayload = this.useSnakedKey
      ? convertKeyToSnakeCase(filteredPayload)
      : filteredPayload

    if (payload.filters) {
      Object.entries(payload.filters).forEach(([key, value]) => {
        const fieldName = this.useSnakedKey ? snakeCase(key) : key
        serializedPayload[`${fieldName}`] = value
      })
    }

    if (payload.sorts) {
      Object.entries(payload.sorts).forEach(([key, value]) => {
        const fieldName = this.useSnakedKey ? snakeCase(key) : key
        serializedPayload[`sorts[${fieldName}]`] = value
      })
    }

    return payloadWrapper ? { [payloadWrapper]: payload } : serializedPayload
  }

  private processMultipartPayload(payloadWrapper?: string, payload: Payload = {}) {
    let data: any = payload

    if (payloadWrapper) {
      data = { [payloadWrapper]: payload }
    }

    return serialize(data, {
      nullsAsUndefineds: true,
      useSnakedKey: this.useSnakedKey,
    })
  }

  private async axiosConfig(extraConfig: AxiosRequestConfig = {}) {
    const authorizationBearer = await loadString(ACCESS_TOKEN_STORAGE_KEY)
    const refreshToken = await loadString(REFRESH_TOKEN_STORAGE_KEY)
    const locale = await loadString(LANGUAGE_STORAGE_KEY)

    const config: AxiosRequestConfig = {
      headers: {
        authorization: authorizationBearer || '',
        'refresh-token': refreshToken || '',
        'accept-language': locale,
        ...extraConfig?.headers,
      },
      ...omit(extraConfig, ['headers']),
    }

    if (this.multipart && config.headers) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }

    return config
  }

  private async saveJWToken(response: ApiResponse<any>) {
    if (response.ok) {
      const authorization = get(response.headers, 'authorization')
      const refreshToken = get(response.headers, 'refresh-token')

      const splittedAuthorization = split(authorization, ' ')

      if (authorization) await saveString(ACCESS_TOKEN_STORAGE_KEY, splittedAuthorization[1])
      if (refreshToken) await saveString(REFRESH_TOKEN_STORAGE_KEY, refreshToken)
    }
  }

  private async processResult(response: ApiResponse<any>) {
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    await this.saveJWToken(response)

    return Promise.resolve(response)
  }

  protected async callApi(
    method: RequestMethod,
    { path, payloadWrapper, payload, config = {} }: ApiParams
  ) {
    const multipart = this.multipart && ['post', 'put', 'patch'].includes(method)

    const wrappedPayload = multipart
      ? this.processMultipartPayload(payloadWrapper, payload)
      : this.processPayload(payloadWrapper, payload)

    const axiosConfig: any = await this.axiosConfig()

    const response: ApiResponse<any> = await this.apisauce[method](path, wrappedPayload, {
      ...axiosConfig,
      ...config,
    })

    return await this.processResult(response)
  }

  protected async get(apiParams: ApiParams) {
    return await this.callApi('get', apiParams)
  }

  protected async post(apiParams: ApiParams) {
    return await this.callApi('post', apiParams)
  }

  protected async put(apiParams: ApiParams) {
    return await this.callApi('put', apiParams)
  }

  protected async patch(apiParams: ApiParams) {
    return await this.callApi('patch', apiParams)
  }

  protected async delete(apiParams: ApiParams) {
    return await this.callApi('delete', apiParams)
  }
}
