/* eslint-disable @typescript-eslint/ban-types */
import { ApiCore } from './api-core'

export class AuthApi extends ApiCore {
  async register(payload = {}) {
    return await this.post({
      path: '/auth/creator/register',
      payload,
    })
  }

  async forgotPassword(payload = {}) {
    return await this.post({
      path: '/auth/forgot-password',
      payload,
    })
  }

  async verifyTokenEmail(payload = {}) {
    return await this.post({
      path: '/auth/creator/verify',
      payload,
    })
  }

  async verifyTokenResetPassword(payload = {}) {
    return await this.post({
      path: '/auth/reset-password/verify',
      payload,
    })
  }

  async login(payload = {}) {
    return await this.post({
      path: '/auth/creator/login',
      payload,
    })
  }

  async resetPassword(payload = {}) {
    return await this.post({
      path: '/auth/reset-password',
      payload,
    })
  }

  async sendVerifyEmail(payload = {}) {
    return await this.post({
      path: '/auth/creator/verify/send',
      payload,
    })
  }

  async refreshToken(payload = {}) {
    return await this.get({
      path: '/auth/refresh-token',
      payload,
    })
  }

  async logout() {
    return await this.delete({ path: '/auth/logout' })
  }
}

export const authApi = new AuthApi()
