import React, { FunctionComponent } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Otptimer } from 'otp-timer-ts'

import { FormInput, SubmitButton, VerticalForm } from 'components'

import { useStores } from 'models'
import useRegisterVerification from './verification-email.hook'
import Icon from 'components/icon'
import { Link } from 'react-router-dom'

const VerificationEmail: FunctionComponent = observer(() => {
  const { t } = useTranslation()

  const {
    authStore: { email },
  } = useStores()

  const { isLoading, error, onVerifyEmail, onResendVerifyEmail, handleBack, tokenResolver } =
    useRegisterVerification()

  return (
    <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
      <div className='container'>
        <Row className='justify-content-center'>
          <Col md={8} lg={6} xl={5} xxl={4}>
            <Card>
              <Card.Header className='py-1 text-center bg-black'></Card.Header>
              <Card.Body className='px-4 py-3'>
                <div className='m-auto'>
                  <div className='tittle-auth mt-0 d-flex align-content-center'>
                    <Link
                      to='/account/register'
                      className='btn-unstyled m-0 p-0'
                      onClick={handleBack}
                    >
                      <Icon icon='icon-arrow_to_left icons-auth' />
                    </Link>
                    <span className='fw-600'>{t('Verify your email')}</span>
                  </div>
                  <p className='text-neutral-4 my-2'>
                    {t('We’ve sent a verification to')} <br /> {email},
                    <span className='text-primary-green clickable' onClick={handleBack}>
                      {t(' Change Email')}
                    </span>
                  </p>
                </div>
                <VerticalForm onSubmit={onVerifyEmail} resolver={tokenResolver}>
                  <FormInput
                    label={t('Verification Code')}
                    type='number'
                    name='token'
                    placeholder={t('Enter your code')}
                  />
                  {error && <p className='text-danger mb-0 mt-1 font-13'>{error}</p>}
                  <div className='otp-timer mt-1 text-neutral-4'>
                    <Otptimer
                      minutes={4}
                      seconds={59}
                      text={t('Resend code will available in: ')}
                      buttonText={t('Resend Code')}
                      onResend={onResendVerifyEmail}
                    />
                  </div>
                  <div className='d-flex justify-content-center mt-3'>
                    <SubmitButton size='lg' loading={isLoading}>
                      {t('Verify')}
                    </SubmitButton>
                  </div>
                </VerticalForm>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
})

export default VerificationEmail
