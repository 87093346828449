import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import moment from 'moment'
import { CreatorPersonalInfo } from './profile'
import { OrganizationType } from 'types/global.types'

export const UserModel = types
  .model('User')
  .props({
    id: types.maybe(types.number),
    fullName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    userEntity: types.maybeNull(types.string),
    userLevel: types.maybeNull(types.number),
    createdAt: types.maybeNull(types.string),
    createdEventsCount: types.maybeNull(types.number),
    joinedEventsCount: types.maybeNull(types.number),
    photoProfile: types.maybeNull(
      types.model({
        id: types.maybeNull(types.number),
        url: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
      })
    ),
    phoneNumber: types.maybeNull(types.string),
    phoneNumberCode: types.maybeNull(types.string),
    birthday: types.maybeNull(types.string),
    creatorPersonal: types.maybeNull(CreatorPersonalInfo),
  })
  .views((self) => ({
    get formattedBirthday() {
      return moment(self.birthday).format('DD MMMM YYYY')
    },
    get organizationType(): OrganizationType {
      switch (self.creatorPersonal?.personalInfoType) {
        case 1:
          return 'individual'
        case 2:
          return 'company'
        case 3:
          return 'charity'
        default:
          return 'individual'
      }
    },
  }))

export type UserInstance = Instance<typeof UserModel>
export type User = Omit<UserInstance, symbol>
export type UserSnapshot = SnapshotOut<typeof UserModel>
