import { Instance, SnapshotOut, types, applySnapshot } from 'mobx-state-tree'
import { PaymentStoreModel } from './payment-store'
import { AppStateStoreModel } from './app-state-store'
import { ToastStoreModel } from './toast-store'
import { AuthStoreModel } from './auth-store'
import { ChallengeStoreModel } from './challenge-store'
import { ProfileStoreModel } from './profile-store'
import { AnalyticStoreModel } from './analytic-store'
import { TutorialsStoreModel } from './tutorials-store'
import { UserStoreModel } from './user-management-store'
/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model('RootStore')
  .props({
    paymentStore: types.optional(PaymentStoreModel, {}),
    appStateStore: types.optional(AppStateStoreModel, {}),
    toastStore: types.optional(ToastStoreModel, {}),
    authStore: types.optional(AuthStoreModel, {}),
    challengeStore: types.optional(ChallengeStoreModel, {}),
    profileStore: types.optional(ProfileStoreModel, {}),
    analyticStore: types.optional(AnalyticStoreModel, {}),
    tutorialsStore: types.optional(TutorialsStoreModel, {}),
    userManagementStore: types.optional(UserStoreModel, {}),
  })
  .actions((self) => ({
    reset(): void {
      applySnapshot(self, {
        appStateStore: self.appStateStore,
      })
    },
  }))

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>
/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>
