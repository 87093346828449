import React, { FunctionComponent } from 'react'
import { Alert, Button, Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { FormInput, VerticalForm } from 'components'

import useForgotPassword from './forgot-password.hook'

const ForgotPassword: FunctionComponent = observer(() => {
  const { t } = useTranslation()

  const { forgotPassResolver, doForgotPassword, error, backTo } = useForgotPassword()

  return (
    <>
      <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5} xxl={4}>
              <Card>
                <Card.Header className='py-1 text-center bg-black'></Card.Header>
                <Card.Body className='px-4 py-3'>
                  <div className='m-auto'>
                    <p className='tittle-auth mt-0 d-flex align-content-center'>
                      <i onClick={backTo} className='icon-arrow_to_left icons-auth ' />
                      {t('Reset password')}
                    </p>
                    <p className='text-neutral-4 mb-3 fw-semibold'>
                      {t(
                        'Enter the email connected with your account and we’ll send anverification code to your email.'
                      )}
                    </p>
                  </div>
                  {error && (
                    <Alert variant='danger' className='my-2'>
                      {error}
                    </Alert>
                  )}
                  <VerticalForm onSubmit={doForgotPassword} resolver={forgotPassResolver}>
                    <FormInput
                      label={t('Email')}
                      type='text'
                      name='email'
                      placeholder={t('Enter your email')}
                      containerClass={'mb-3'}
                    />
                    <div className='text-center'>
                      <Button variant='primary' type='submit' className='rounded px-4 py-2 fw-600'>
                        {t('Verify Email')}
                      </Button>
                    </div>
                  </VerticalForm>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
})

export default ForgotPassword
