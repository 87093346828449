import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class PaymentApi extends ApiCore {
  async getOrganizerPayments(payload: Payload = {}) {
    return await this.get({
      path: '/payouts',
      payload,
    })
  }

  async getOrganizerPayment(challengeId: string) {
    return await this.get({
      path: `/payouts/${challengeId}`,
    })
  }

  async getOrganizerRefunds(challengeId: string, payload: Payload = {}) {
    return await this.get({
      path: `refunds/admin?challengeID=${challengeId}`,
      payload,
    })
  }

  async getAdminPayouts(payload: Payload = {}) {
    return await this.get({
      path: '/payouts/admin',
      payload,
    })
  }

  async getDetailAdminPayout(challengeId: string, userId: string) {
    return await this.get({
      path: `/payouts/admin/${challengeId}/${userId}`,
    })
  }

  async getAdminRefunds(payload: Payload = {}) {
    return await this.get({
      path: '/refunds/admin',
      payload,
    })
  }

  async getDetailAdminRefunds(challengeId: string, userId: string) {
    return await this.get({
      path: `/refunds/${challengeId}/${userId}`,
    })
  }

  async updateAdminRefundStatus(transactionId: number, payload: Payload = {}) {
    return await this.patch({
      path: `/refunds/admin/${transactionId}`,
      payload,
    })
  }
}

export const paymentApi = new PaymentApi()
