import { buildChallengePayload } from 'services/helpers/buildChallengePayload'
import { ApiCore } from './api-core'
import { Payload } from './api-core.types'
import { SelectedChallengeType } from 'models/selected-challenge'

export class ChallengeApi extends ApiCore {
  async deleteChallenges(id: string) {
    return await this.delete({
      path: `/challenges/${id}`,
    })
  }

  async deleteChallengeTicket(ticketId: string, challengeId: string) {
    return await this.delete({
      path: `challenges/tickets/${ticketId}/${challengeId}`,
    })
  }

  async deleteChallengeMedia(mediaId: string, challengeId: string) {
    return await this.delete({
      path: `challenges/medias/${mediaId}/${challengeId}`,
    })
  }

  async deleteChallengeMerchandise(merchandiseId: string, challengeId: string) {
    return await this.delete({
      path: `challenges/merchandises/${merchandiseId}/${challengeId}`,
    })
  }

  async deleteChallengeTask(taskId: string) {
    return await this.delete({
      path: `tasks/${taskId}`,
    })
  }

  async getChallenges(payload: Payload = {}) {
    return await this.get({
      path: '/challenges/created',
      payload,
    })
  }

  async getChallengesAnalytics(payload: Payload = {}) {
    return await this.get({
      path: 'challenges/analytics',
      payload,
    })
  }

  async getChallengesDetails(id: string, payload: Payload = {}) {
    return await this.get({
      path: `challenges/details/${id}`,
      payload,
    })
  }

  async getEventManagementChallengesDetails(id: string, payload: Payload = {}) {
    return await this.get({
      path: `challenges/${id}`,
      payload,
    })
  }

  async addChallenges(payload: Payload & SelectedChallengeType) {
    return await this.post({
      path: '/challenges',
      payload: buildChallengePayload(payload),
    })
  }

  async updateChallenges(id: string, payload: Payload & SelectedChallengeType) {
    return await this.put({
      path: `/challenges/${id}`,
      payload: buildChallengePayload(payload),
    })
  }

  async getChallengesParticipants(id: string, payload: Payload = {}) {
    return await this.get({
      path: `/challenges/${id}/participants?deletedUserParticipant=false`,
      payload,
    })
  }

  async deleteParticipantFromChallenges(challengeId: string, participantId: string) {
    return await this.delete({
      path: `/challenges/${challengeId}/participants/${participantId}`,
    })
  }

  async checkInParticipant(challengeId: string, participantId: string) {
    return await this.post({
      path: `/challenges/${challengeId}/participants/${participantId}/check-in`,
    })
  }

  async deleteCheckInParticipant(challengeId: string, participantId: string) {
    return await this.delete({
      path: `/challenges/${challengeId}/participants/${participantId}/check-in`,
    })
  }

  async getDeletedParticipants(id: string, payload: Payload = {}) {
    return await this.get({
      path: `/challenges/${id}/participants?deletedUserParticipant=true`,
      payload,
    })
  }

  async getEventsCalendar(payload: Payload = {}) {
    return await this.get({
      path: '/challenges/calendar',
      payload,
    })
  }

  async donwloadChallenges() {
    return await this.get({
      path: '/challenges/download',
      config: {
        responseType: 'blob',
      },
    })
  }

  async donwloadCheckIn(id: string) {
    return await this.get({
      path: `challenges/${id}/participants/download`,
      config: {
        responseType: 'blob',
      },
    })
  }
}

export const challengeApi = new ChallengeApi()
