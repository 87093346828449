import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import { AddOn, BaseChallenge } from './challenge'

export const Task = types.model('Task', {
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  taskDate: types.maybeNull(types.string),
  completed: types.optional(types.maybeNull(types.boolean), false),
  additionalLink: types.maybeNull(types.string),
  detail: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
  createdAt: types.optional(types.maybeNull(types.string), ''),
  updatedAt: types.optional(types.maybeNull(types.string), ''),
})

export const SelectedChallengeModel = BaseChallenge.props({
  videoLink: types.optional(types.maybeNull(types.string), ''),
  videoLinkID: types.optional(types.maybeNull(types.number), null),
  add_ons: types.maybeNull(AddOn),
  tasks: types.optional(types.maybeNull(types.array(Task)), []),
})

export type TaskModelInstance = Instance<typeof Task>
export type TaskType = Omit<TaskModelInstance, symbol>
export type TaskModelSnapshot = SnapshotOut<typeof Task>

export type SelectedChallengeModelInstance = Instance<typeof SelectedChallengeModel>
export type SelectedChallengeType = Omit<SelectedChallengeModelInstance, symbol>
export type SelectedChallengeModelSnapshot = SnapshotOut<typeof SelectedChallengeModel>
