import { Instance, SnapshotOut, applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree'

import { ApiSuccessResult, Payload, challengeApi, superAdminApi } from 'services/api'

import { ChallengeManagementListModel, ChallengeModel } from './challenge'
import { withSetValue } from './helpers/with-set-value'
import { ParticipantsModel } from './participants-store'
import { SelectedChallengeModel, SelectedChallengeType } from './selected-challenge'
import { EventsCalenderModel } from './eventsCalender'
import { FormEventCompletedModel, FormEventCompletedType } from './form-event-completed'

export const ChallengeStoreModel = types
  .model('ChallengeStore')
  .props({
    challengesList: types.array(ChallengeModel),
    challengesParticipantsList: types.array(ParticipantsModel),
    deletedParticipantsList: types.array(ParticipantsModel),
    selectedChallenge: types.optional(SelectedChallengeModel, {}),
    eventsCalenderList: types.array(EventsCalenderModel),
    challengeManagement: types.array(ChallengeManagementListModel),
    totalPage: types.optional(types.number, 1),
    tabId: types.optional(types.number, 0),
    completed: types.optional(FormEventCompletedModel, {
      details: false,
      media: false,
      price: false,
      tasks: false,
      safetyGuidelines: false,
    }),
  })
  .extend(withSetValue)
  .views((self) => ({
    get challenges() {
      return getSnapshot(self.challengesList)
    },
    get challengesParticipants() {
      return getSnapshot(self.challengesParticipantsList)
    },
    get deletedParticipants() {
      return getSnapshot(self.deletedParticipantsList)
    },
    get challenge() {
      return getSnapshot(self.selectedChallenge)
    },
    get isAiEvent() {
      return getSnapshot(self.selectedChallenge)?.type === 1
    },
    get eventsCalender() {
      return getSnapshot(self.eventsCalenderList)
    },
    get isAllCompleted() {
      const snapshot = getSnapshot(self.completed)
      return Object.entries(snapshot)
        .filter(([key]) => !['tasks', 'safetyGuidelines'].includes(key))
        .every(([, value]) => Boolean(value))
    },
  }))
  .actions((self) => ({
    getTab: function (id: number) {
      self.tabId = id
    },

    setCompleted: function (completed: FormEventCompletedType) {
      self.completed = completed
    },
    resetCompleted: function () {
      self.completed = {
        details: false,
        media: false,
        price: false,
        tasks: false,
        safetyGuidelines: false,
      }
    },

    getChallenges: flow(function* (payload?) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.sorts) {
        payload.direction = payload.sorts[Object.keys(payload.sorts)[0]]
        payload.sort = Object.keys(payload.sorts)[0]
        delete payload.sorts
      }

      const result: ApiSuccessResult = yield challengeApi.getChallenges(payload)
      if (result.ok) {
        applySnapshot(self.challengesList, result.data.data.datas)
        applySnapshot(self.selectedChallenge, {})
      }
      self.totalPage = result.data.data.totalPages

      return result.ok
    }),

    getChallengeDetails: flow(function* (id) {
      const result: ApiSuccessResult = yield challengeApi.getChallengesDetails(id)

      if (result.ok) {
        applySnapshot(self.selectedChallenge, {})
        applySnapshot(self.challengesParticipantsList, [])
        applySnapshot(self.deletedParticipantsList, [])
        applySnapshot(self.selectedChallenge, result.data.data)
      }

      return result.ok
    }),

    getEventManagementChallengeDetails: flow(function* (id) {
      const result: ApiSuccessResult = yield challengeApi.getEventManagementChallengesDetails(id)

      if (result.ok) {
        applySnapshot(self.selectedChallenge, {})
        applySnapshot(self.challengesParticipantsList, [])
        applySnapshot(self.deletedParticipantsList, [])
        applySnapshot(self.selectedChallenge, result.data.data)
      }

      return result.ok
    }),

    deleteChallenge: flow(function* (id) {
      const result: ApiSuccessResult = yield challengeApi.deleteChallenges(id)

      return result.ok
    }),

    submitChallenge: flow(function* (id?: string, payload?: Payload & SelectedChallengeType) {
      const { ok }: ApiSuccessResult = id
        ? yield challengeApi.updateChallenges(id, payload)
        : yield challengeApi.addChallenges(payload)

      return ok
    }),

    deleteChallengeTicket: flow(function* (ticketId, challengeId) {
      const result: ApiSuccessResult = yield challengeApi.deleteChallengeTicket(
        ticketId,
        challengeId
      )

      return result.ok
    }),

    deleteChallengeMedia: flow(function* (mediaId, challengeId) {
      const result: ApiSuccessResult = yield challengeApi.deleteChallengeMedia(mediaId, challengeId)

      return result.ok
    }),

    deleteChallengeMerchandise: flow(function* (merchandiseId, challengeId) {
      const result: ApiSuccessResult = yield challengeApi.deleteChallengeMerchandise(
        merchandiseId,
        challengeId
      )

      return result.ok
    }),

    deleteChallengeTask: flow(function* (taskId) {
      const result: ApiSuccessResult = yield challengeApi.deleteChallengeTask(taskId)

      return result.ok
    }),

    getChallengesParticipants: flow(function* (id, payload?) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      const result: ApiSuccessResult = yield challengeApi.getChallengesParticipants(id, payload)
      if (result.ok) {
        applySnapshot(self.challengesParticipantsList, [])
        applySnapshot(self.challengesParticipantsList, result.data.data.datas)
      }
      self.totalPage = result.data.data.totalPages

      return result.ok
    }),
    deleteParticipantFromChallenge: flow(function* (challengeId: string, participantId: string) {
      const result: ApiSuccessResult = yield challengeApi.deleteParticipantFromChallenges(
        challengeId,
        participantId
      )

      return result.ok
    }),
    checkInParticipant: flow(function* (challengeId: string, participantId: string) {
      const result: ApiSuccessResult = yield challengeApi.checkInParticipant(
        challengeId,
        participantId
      )

      return result.ok
    }),
    deleteCheckInParticipant: flow(function* (challengeId: string, participantId: string) {
      const result: ApiSuccessResult = yield challengeApi.deleteCheckInParticipant(
        challengeId,
        participantId
      )

      return result.ok
    }),
    downloadChallenges: flow(function* () {
      const result: ApiSuccessResult = yield challengeApi.donwloadChallenges()

      return result
    }),
    downloadCheckIn: flow(function* (id: string) {
      const result: ApiSuccessResult = yield challengeApi.donwloadCheckIn(id)

      return result
    }),
    getDeletedParticipants: flow(function* (id, payload?) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      const result: ApiSuccessResult = yield challengeApi.getDeletedParticipants(id, payload)
      if (result.ok) {
        applySnapshot(self.deletedParticipantsList, [])
        applySnapshot(self.deletedParticipantsList, result.data.data.datas)
      }
      self.totalPage = result.data.data.totalPages

      return result.ok
    }),
    getEventsCalendar: flow(function* (payload?) {
      const result: ApiSuccessResult = yield challengeApi.getEventsCalendar(payload)

      if (result.ok) {
        applySnapshot(self.eventsCalenderList, [])
        applySnapshot(self.eventsCalenderList, result.data.data)
      }

      return result.ok
    }),

    resetSelectedChallenge: function () {
      applySnapshot(self.selectedChallenge, {})
    },

    getEventsManagement: flow(function* (payload?) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.filters?.sort) {
        const bracketIndex = payload.filters.sort.indexOf('[')
        if (bracketIndex !== -1) {
          payload.sort = payload.filters.sort.slice(0, bracketIndex)
          payload.direction = payload.filters.sort.slice(bracketIndex + 1, -1)
        }

        delete payload.filters.sort
      }

      const result: ApiSuccessResult = yield superAdminApi.getEventLists(payload)
      if (result.ok) applySnapshot(self.challengeManagement, result.data.data.datas)
      self.totalPage = result.data.data.totalPages

      return result.ok
    }),

    downloadEventsManagement: flow(function* () {
      const result: ApiSuccessResult = yield superAdminApi.downloadEventManagementList()

      return result
    }),

    changeEventStatus: flow(function* (id, payload) {
      const result: ApiSuccessResult = yield superAdminApi.changeStatusEventApproval(id, payload)

      return result.ok
    }),
  }))

export type ChallengeStore = Instance<typeof ChallengeStoreModel>
export type ChallengeStoreSnapshot = SnapshotOut<typeof ChallengeStoreModel>
