import React, { FunctionComponent, useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, InputGroup, FormControl, Button, Dropdown } from 'react-bootstrap'
import { PhoneInputWithDropdownProps, PhoneInputProps } from './phone-number.props'
import classNames from 'classnames'

const PhoneCodeDropdown: FunctionComponent<PhoneInputProps> = ({
  data,
  value,
  onChange,
  placeholder,
  error,
  disabled,
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [displayedLabel, setDisplayedLabel] = useState('🇬🇷 +30')

  useEffect(() => {
    if (!value && data?.length > 0) {
      const defaultItem = data[0]
      setDisplayedLabel(`${defaultItem.countryIcon} +${defaultItem.phoneCode}`)
      onChange(defaultItem.phoneCode)
    } else if (value) {
      const selectedItem = data.find((item: any) => item.phoneCode === value)

      setDisplayedLabel(
        selectedItem ? `${selectedItem.countryIcon} +${selectedItem.phoneCode}` : placeholder
      )
    }
  }, [value, data])

  const handlePhoneCodeChange = (id: string) => {
    const selectedItem = data.find((item: any) => item.id === id)

    if (selectedItem) {
      setDisplayedLabel(`${selectedItem.countryIcon} +${selectedItem.phoneCode}`)
      onChange(selectedItem.phoneCode)
      setDropdownVisible(false)
    }
  }

  return (
    <Dropdown
      show={isDropdownVisible}
      onToggle={() => setDropdownVisible(!isDropdownVisible)}
      className={error ? 'is-invalid' : ''}
    >
      <Dropdown.Toggle
        as={Button}
        variant='outline-secondary'
        className={classNames(disabled ? 'disabled' : '')}
      >
        {displayedLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu className='custom-dropdown-menu' align={'start'}>
        {data?.map((item: any) => (
          <Dropdown.Item key={item.id} onClick={() => handlePhoneCodeChange(item.id)}>
            {item.countryIcon} {item.countryName} (+{item.phoneCode})
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const PhoneInputWithDropdown: FunctionComponent<PhoneInputWithDropdownProps> = ({
  containerClass,
  name,
  phoneCodeName,
  label,
  placeholder = '',
  data,
  required = false,
  disabled = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Form.Group className={containerClass}>
      {label && (
        <div className='d-flex gap-1'>
          <Form.Label>{label}</Form.Label>
          {required && <span className='text-primary'>*</span>}
        </div>
      )}
      <InputGroup>
        <Controller
          name={phoneCodeName}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <PhoneCodeDropdown
              data={data}
              value={value}
              onChange={onChange}
              placeholder='Select a phone code'
              error={error}
              disabled={disabled}
            />
          )}
        />
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              as='input'
              value={value}
              onChange={onChange}
              isInvalid={!!error}
              placeholder={disabled ? placeholder : 'Enter your phone number'}
              type='number'
              disabled={disabled}
            />
          )}
        />
        <Form.Control.Feedback type='invalid'>{errors[name]?.message}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  )
}
