import classNames from 'classnames'
import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useController, useFormContext } from 'react-hook-form'

import 'react-datepicker/dist/react-datepicker.css'

import { DateRangeInputProps } from './date-range-input.types'

export const DateRangeInput = (props: DateRangeInputProps) => {
  const { label, containerClass, placeholder, startName, endName, onChange } = props

  const { control } = useFormContext()

  const {
    field: { onChange: onStartDateChange, value: startDate },
    fieldState: { invalid: startInvalid, error: startError },
  } = useController({ control, name: startName })

  const {
    field: { onChange: onEndDateChange, value: endDate },
    fieldState: { invalid: endInvalid, error: endError },
  } = useController({ control, name: endName })

  const onDatesSelected = (update: [Date, Date]) => {
    onStartDateChange(update[0])
    onEndDateChange(update[1])
  }

  const selectValue = useMemo(() => {
    const values = []

    if (startDate) values.push(new Date(startDate))

    if (endDate) values.push(new Date(endDate))

    return values
  }, [startDate, endDate])

  return (
    <Form.Group className={containerClass}>
      {label && <Form.Label>{label}</Form.Label>}
      <DatePicker
        selectsRange={true}
        className={classNames('form-control', {
          'is-invalid': startInvalid || endInvalid,
        })}
        placeholderText={placeholder}
        startDate={selectValue[0]}
        endDate={selectValue[1]}
        onChange={(update: any) => {
          onChange && onChange()
          onDatesSelected(update)
        }}
        isClearable={true}
      />
      {(startError || endError) && (
        <Form.Control.Feedback type='invalid' className='d-block'>
          {startError.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
