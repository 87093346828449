import { Instance, SnapshotOut, applySnapshot, flow, types } from 'mobx-state-tree'

import { ApiSuccessResult, superAdminApi } from 'services/api'

import { withReset } from './helpers/with-reset'
import { UserModel } from './user-management'

export const UserStoreModel = types
  .model('UserStore')
  .props({
    userList: types.array(UserModel),
    selectedUser: types.optional(UserModel, {}),
    totalPage: types.optional(types.number, 1),
    tabId: types.optional(types.number, 0),
  })
  .actions(withReset)
  .actions((self) => ({
    getTab: function (id: number) {
      self.tabId = id
    },

    getUserList: flow(function* (payload?) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.filters?.sort) {
        const bracketIndex = payload.filters.sort.indexOf('[')
        if (bracketIndex !== -1) {
          payload.sort = payload.filters.sort.slice(0, bracketIndex)
          payload.direction = payload.filters.sort.slice(bracketIndex + 1, -1)
        }

        delete payload.filters.sort
      }

      const result: ApiSuccessResult = yield superAdminApi.getUserLists(payload)

      if (result.ok) {
        result?.data?.data?.datas?.length
          ? applySnapshot(self.userList, result.data.data.datas)
          : applySnapshot(self.userList, [])
      }

      self.totalPage = result.data.data.totalPages

      return result.ok
    }),

    addAdmin: flow(function* (payload) {
      const result: ApiSuccessResult = yield superAdminApi.addAdmin(payload)

      return result.ok
    }),

    getUserDetails: flow(function* (id) {
      const result: ApiSuccessResult = yield superAdminApi.getUsersDetails(id)

      if (result.ok) {
        applySnapshot(self.selectedUser, result.data.data)
      }
      return result.ok
    }),

    deleteUser: flow(function* (id) {
      const result: ApiSuccessResult = yield superAdminApi.deleteUsers(id)

      return result.ok
    }),
  }))

export type UserStore = Instance<typeof UserStoreModel>
export type UserStoreSnapshot = SnapshotOut<typeof UserStoreModel>
