import { Instance, SnapshotOut, applySnapshot, flow, types } from 'mobx-state-tree'
import moment from 'moment'

import { ApiSuccessResult, authApi, formDataApi } from 'services/api'

import { getRootStore } from './helpers/get-root-store'
import { withSetValue } from './helpers/with-set-value'

export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    id: types.maybe(types.number),
    fullName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    password: types.maybeNull(types.string),
    confirmPassword: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    userLevel: types.maybeNull(types.number),
    confirmedAt: types.maybeNull(types.string),
    registrationCompletion: types.maybeNull(
      types.model({
        isComplete: types.maybeNull(types.boolean),
        lastStep: types.maybeNull(types.number),
      })
    ),
    token: types.maybeNull(types.string),
    code: types.maybeNull(types.number),
  })
  .extend(withSetValue)
  .actions((self) => ({
    resetRootStore(): void {
      const rootStore = getRootStore(self)

      rootStore.reset()
    },
  }))
  .actions((self) => ({
    login: flow(function* (email: string, password: string) {
      const result: ApiSuccessResult = yield authApi.login({ email, password })

      if (result.ok) {
        applySnapshot(self, result.data.data)
      }

      return result.ok
    }),

    register: flow(function* (formData: Record<string, any>) {
      const result: ApiSuccessResult = yield authApi.register(formData)

      if (result.ok) {
        applySnapshot(self, formData)
      }

      return result.ok
    }),

    forgotPassword: flow(function* (formData: Record<string, string>) {
      const result: ApiSuccessResult = yield authApi.forgotPassword(formData)

      if (result.ok) {
        applySnapshot(self, { ...self, ...result.data.data })
      }

      return result.ok
    }),

    resetPassword: flow(function* (formData: Record<string, any>) {
      const result: ApiSuccessResult = yield authApi.resetPassword(formData)

      return result.ok
    }),

    verifyEmailToken: flow(function* (formData: Record<string, any>) {
      const result: ApiSuccessResult = yield authApi.verifyTokenEmail(formData)

      return result.ok
    }),

    verifyTokenResetPass: flow(function* (formData: Record<string, any>) {
      const result: ApiSuccessResult = yield authApi.verifyTokenResetPassword(formData)

      return result.ok
    }),

    resendCodeResetPassword: flow(function* (email: Record<string, any>) {
      const result: ApiSuccessResult = yield authApi.forgotPassword(email)

      if (result.ok) applySnapshot(self, result.data.data.token)

      return result.ok
    }),

    sendVerifyEmail: flow(function* (email: Record<string, any>) {
      const result: ApiSuccessResult = yield authApi.sendVerifyEmail(email)

      return result.ok
    }),

    resendVerifyEmail: flow(function* (email: Record<string, any>) {
      const result: ApiSuccessResult = yield authApi.sendVerifyEmail(email)

      return result.ok
    }),

    refreshToken: flow(function* () {
      const result: ApiSuccessResult = yield authApi.refreshToken()

      if (result.ok) applySnapshot(self, result.data.data)

      return result.ok
    }),

    addPersonalInformation: flow(function* (formData: Record<string, any>) {
      const result: ApiSuccessResult = yield formDataApi.addPersonalInformation(formData)

      if (result.ok) applySnapshot(self, result.data.data)

      return result.ok
    }),
  }))
  .views((self) => ({
    get isAuthenticated() {
      return (
        !!self.id &&
        moment.utc(self.confirmedAt).isAfter(moment.utc('0001-01-01')) &&
        self.registrationCompletion.isComplete
      )
    },
    get displayName() {
      return self.fullName || 'John Doe'
    },
    get userTitle() {
      return 'Organizer'
    },
  }))

export type AuthStore = Instance<typeof AuthStoreModel>
export type AuthStoreSnapshot = SnapshotOut<typeof AuthStoreModel>
